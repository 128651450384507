import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "utils/axios";
import PageLoader from "components/ui/PageLoader"; // Assuming this is your loader component
import Error from "components/ui/Error";
import PriceProcess from "components/chat/PriceProcess";
import PostList from "components/chat/Postlist";
import BookAppraisal from "components/chat/BookAppraisal";
import AuthoriseSchedule from "components/chat/AuthoriseSchedule";
import GoLive from "components/chat/GoLive";
import PrepareMarketing from "components/chat/PrepareMarketing";
import OnMarket from "components/chat/OnMarket";
import Compliance from "components/chat/Compliance";
// Removed ReactDOM import as Tooltip is removed

const componentsMap = {
  "Price and Process": PriceProcess,
  "Post List": PostList,
  "Book Appraisal": BookAppraisal,
  "Authorise and Schedule": AuthoriseSchedule,
  "Prepare Marketing": PrepareMarketing,
  "Go Live": GoLive,
  "On Market": OnMarket,
  "Compliance": Compliance, // Add Compliance to the map
};

const Chat = () => {
  const { address } = useParams();
  const decodedAddress = decodeURIComponent(address);

  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [property, setProperty] = useState(null);
  const [selectedBox, setSelectedBox] = useState("");
  const [isBoxLoading, setIsBoxLoading] = useState(false);

  // Refs to each box for scrolling into view
  const boxRefs = useRef([]);
  const scrollContainerRef = useRef(null); // Container ref for scrolling

  const [boxes, setBoxes] = useState([
    {
      title: "Book Appraisal",
      text: "book now, instantly send valuable insights",
      status: "unlock",
    },
    {
      title: "Price and Process",
      text: "sets the price, plans the process, maximises the outcome",
      status: "unlock",
    },
    {
      title: "Post List",
      text: "instant proposals, high state impact",
      status: "unlock",
    },
    {
      title: "Authorise and Schedule",
      text: "schedule campaign, sign instantly",
      status: "unlock",
    },
    {
      title: "Prepare Marketing",
      text: "creates and approves all in one place",
      status: "unlock",
    },
    {
      title: "Go Live",
      text: "launch to market, adjust campaign anytime",
      status: "unlock",
    },
    {
      title: "On Market",
      text: "tracks, analyzes, and improves for maximum results",
      status: "unlock",
    },
  ]);

  // New State Variables for Polling and Cleanup Message
  const [isCleaned, setIsCleaned] = useState(true); // Tracks if cleaning is done

  const searchProperty = async () => {
    try {
      setLoading(true);
      const response = await axios.post("/userProperty", {
        address: decodedAddress,
      });

      if (response.data.success) {
        setProperty(response.data.data);
        checkBoxStatus(response.data.data.boxStatus);
        setIsCleaned(response.data.data.isCleaned); // Set initial cleaning status
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const checkBoxStatus = async (boxStatus = null) => {
    try {
      if (!boxStatus) {
        const response = await axios.get(
          `/userProperty/${encodeURIComponent(decodedAddress)}`
        );
        if (response.data.success) {
          boxStatus = response.data.data.boxStatus;
        }
      }

      if (boxStatus) {
        setBoxes((prevBoxes) =>
          prevBoxes.map((box) => {
            const status = boxStatus.find((b) => {
              switch (b.name) {
                case "bookAppraisal":
                  return box.title === "Book Appraisal";
                case "priceProcess":
                  return box.title === "Price and Process";
                case "postList":
                  return box.title === "Post List";
                case "authoriseSchedule":
                  return box.title === "Authorise and Schedule";
                case "prepareMarketing":
                  return box.title === "Prepare Marketing";
                case "goLive":
                  return box.title === "Go Live";
                case "onMarket":
                  return box.title === "On Market";
                default:
                  return false;
              }
            })?.status;

            return {
              ...box,
              status: status || box.status,
            };
          })
        );

        const selectedBoxStatus = boxStatus.find((b) => {
          switch (b.name) {
            case "bookAppraisal":
              return selectedBox === "Book Appraisal";
            case "priceProcess":
              return selectedBox === "Price and Process";
            case "postList":
              return selectedBox === "Post List";
            case "authoriseSchedule":
              return selectedBox === "Authorise and Schedule";
            case "prepareMarketing":
              return selectedBox === "Prepare Marketing";
            case "goLive":
              return selectedBox === "Go Live";
            case "onMarket":
              return selectedBox === "On Market";
            default:
              return false;
          }
        })?.status;

        if (selectedBoxStatus === "lock") {
          navigate(`/chat/${encodeURIComponent(decodedAddress)}`);
        }
      }
    } catch (error) {
      console.error("Error fetching box status:", error);
    }
  };

  const handleBoxClick = (box, index) => {
    // Allow navigation for all boxes except locked ones
    if (box.status !== "lock") {
      const tabName = box.title.toLowerCase().replace(/\s+/g, "-");
      navigate({ search: `?tab=${tabName}` });
      setSelectedBox(box.title);
      scrollToCenter(index); // Scroll to the clicked tab
    }
  };

  const scrollToCenter = (index) => {
    const selectedBoxElement = boxRefs.current[index];
    const scrollContainer = scrollContainerRef.current;

    if (selectedBoxElement && scrollContainer) {
      const containerWidth = scrollContainer.clientWidth; // Width of the scroll container
      const boxWidth = selectedBoxElement.clientWidth; // Width of the selected box
      const boxOffset = selectedBoxElement.offsetLeft; // Left position of the selected box

      // Calculate the scroll position to center the box
      const scrollPos = boxOffset - containerWidth / 2 + boxWidth / 2 - 20;

      // Smooth scrolling to the calculated position
      scrollContainer.scrollTo({ left: scrollPos, behavior: "smooth" });
    }
  };

  useEffect(() => {
    searchProperty();
    const initialTab = new URLSearchParams(location.search).get("tab");
  
    if (initialTab === "compliance") {
      setSelectedBox("Compliance"); // Directly set to "Compliance"
      // No need to scroll since it's not part of the boxes
    } else {
      const selectedIndex = boxes.findIndex(
        (box) => box.title.toLowerCase().replace(/\s+/g, "-") === initialTab
      );
      if (initialTab && selectedIndex !== -1) {
        setSelectedBox(boxes[selectedIndex].title);
        scrollToCenter(selectedIndex); // Scroll to the selected tab from URL
      } else {
        setSelectedBox(""); // Default state when no valid tab is selected
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, location.search]);

  useEffect(() => {
    // Scroll the selected box into center when component is fully rendered
    const selectedIndex = boxes.findIndex((box) => box.title === selectedBox);
    if (selectedIndex !== -1) {
      scrollToCenter(selectedIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxes, selectedBox]);

  // // Updated useEffect for Polling isCleaned Status
  // useEffect(() => {
  //   // Start polling only if the property exists and isCleaned is false
  //   if (property && !isCleaned) {
  //     const interval = setInterval(async () => {
  //       try {
  //         const response = await axios.get(
  //           `/userProperty/${encodeURIComponent(decodedAddress)}`
  //         );
  //         if (response.data.success) {
  //           const updatedData = response.data.data;
  //           setProperty(updatedData); // Update the property state with latest data
  //           setIsCleaned(updatedData.isCleaned); // Update the isCleaned state

  //           checkBoxStatus(updatedData.boxStatus); // Update box statuses

  //           if (updatedData.isCleaned) {
  //             setProperty(updatedData);
  //             clearInterval(interval); // Stop polling
  //             // Optionally, perform any additional actions here
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error checking isCleaned:", error);
  //         // Optionally, handle the error (e.g., show a notification)
  //       }
  //     }, 10000); // Poll every 10 seconds

  //     // Cleanup interval on component unmount or when isCleaned becomes true
  //     return () => clearInterval(interval);
  //   }
  // }, [property, decodedAddress, isCleaned]);

  const CurrentComponent = componentsMap[selectedBox] || null;

  if (error) {
    return <Error />;
  }

  return (
    <div className="container">
      {!selectedBox && (
        <div className="py-8">
          <p className="text-center text-sm text-darkergray">
            {decodedAddress}
          </p>
        </div>
      )}

      {/* Render the tabs regardless of loading state */}
      <div
        className={`w-full fixed left-0 right-0 bg-white px-6 flex items-center justify-center ${
          selectedBox === "Post List" &&
          boxes.find((box) => box.title === "Post List")?.status !== "complete"
            ? "bottom-8 pt-2 pb-12"
            : "bottom-0 pb-12 pt-2"
        }`}
        style={{ zIndex: 1001, overflow: "visible" }} // Ensure overflow is visible
      >
        <div
          ref={scrollContainerRef}
          className="overflow-x-auto whitespace-nowrap box-scrollbar scroll-smooth"
        >
          {boxes.map((box, index) => (
            <div
              key={index}
              ref={(el) => {
                boxRefs.current[index] = el;
              }}
              className={`rounded-xl flex-shrink-0 inline-flex flex-col items-center mr-4 py-2.5 px-6 relative
                ${
                  box.status === "lock"
                    ? "cursor-default bg-lightgray"
                    : "cursor-pointer "
                }
                ${
                  box.title === selectedBox && box.status !== "complete"
                    ? "bg-mediumgray"
                    : ""
                }
                ${
                  box.status === "complete"
                    ? "bg-black text-white hover:bg-black"
                    : " bg-lightgray hover:bg-mediumgray"
                }
                `}
              onClick={() => handleBoxClick(box, index)}
            >
              <div
                className={`relative  ${
                  isBoxLoading || box.title === selectedBox
                    ? "text-center min-w-[200px]"
                    : "text-start"
                }`}
              >
                {!isBoxLoading || box.title !== selectedBox ? (
                  <div className="text-xs m-0">
                    <div className="flex items-center">
                      {box.status === "lock" && (
                        <i className="fas fa-lock mr-2"></i>
                      )}
                      <h6>{box.title}</h6>
                      {/* Optional: Add spinner on "Price and Process" tab */}
                      {/* {box.title === "Price and Process" && !isCleaned && (
                        <i className="fa-solid fa-spinner animate-spin ml-2 text-sm"></i>
                      )} */}
                    </div>
                    <span className=" text-darkergray">{box.text}</span>
                  </div>
                ) : (
                  <i className="fa-solid fa-spinner animate-spin"></i>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Conditional rendering for the content of the selected tab */}
      <div className="pb-28">
        {loading ? (
          <div
            className="fixed top-0 left-0 w-full h-full flex justify-center items-center"
            style={{
              zIndex: 1000,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <PageLoader />
          </div>
        ) : (
          CurrentComponent && (
            <CurrentComponent
              property={property} // Updated property data
              updateBoxLoading={setIsBoxLoading}
              updateBoxStatus={checkBoxStatus}
              isCleaned={isCleaned} // Pass isCleaned here
            />
          )
        )}
      </div>
    </div>
  );
};

export default Chat;
