// // utils/axios.js
// import axios from 'axios';

// const api = axios.create({
//   baseURL: process.env.REACT_APP_BACKEND_URL,
//   withCredentials: true,
// });

// export default api;


// utils/axios.js
import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

// Add a response interceptor to handle 401 Unauthorized globally
api.interceptors.response.use(
  (response) => response, // Return the response if successful
  (error) => {
    // Check if the error is a 401 Unauthorized error
    if (error.response && error.response.status === 401) {
      // Redirect to login page using window.location
      window.location.href = '/login'; // Redirects to the login page
    }
    // Return a rejected promise to handle the error in the component if needed
    return Promise.reject(error);
  }
);

export default api;