// // src/context/AuthContext.js
// import React, { createContext, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom"; // For programmatic navigation
// import api from "../utils/axios"; // Import your custom axios instance

// const checkAuthStatus = async () => {
//   try {
//     const response = await api.get("/auth/status", {
//       withCredentials: true, // Ensures cookies are sent with the request
//     });
//     if (response.data.cookies && response.data.success) {
//       return response.data.data; // Return the user data if successful
//     }
//   } catch (error) {
//     console.error("Error checking auth status:", error.message);
//     return null; // Return null or handle the failure case appropriately
//   }
// };

// export const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate(); // Use for redirection

//   useEffect(() => {
//     const fetchUser = async () => {
//       const user = await checkAuthStatus();
//       if (user) {
//         setUser(user);
//       }
//       setLoading(false);
//     };

//     fetchUser();

//     // Polling:
//     const interval = setInterval(async () => {
//       const user = await checkAuthStatus();
//       if (!user) {
//         navigate("/login"); // If session expired, redirect to login
//       }
//     }, 300000); // Check every 5 mins

//     return () => clearInterval(interval); // Clear the interval when component unmounts
//   }, [navigate]);

//   return (
//     <AuthContext.Provider value={{ user, setUser, loading }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };


import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../utils/axios";

const checkAuthStatus = async () => {
  try {
    const response = await api.get("/auth/status", {
      withCredentials: true,
    });
    if (response.data.cookies && response.data.success) {
      return response.data.data;
    }
  } catch (error) {
    console.error("Error checking auth status:", error.message);
    return null;
  }
};

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Paths that should not redirect to the login page
  const exemptPaths = ["/share", "/prelist", "/postlist", "/agreement", "/esign", "/upload"];

  // Function to get a specific cookie value
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  // Fallback cookie expiration (in milliseconds), since session cookies may not have an explicit expiration
  const fallbackExpirationTime = 1 * 60 * 60 * 1000; // 1 hour

  // Check if the path is exempt
  const isExemptPath = () => {
    return exemptPaths.some((exemptPath) => location.pathname.includes(exemptPath));
  };

  useEffect(() => {
    const checkSessionAndFetchUser = async () => {
      const user = await checkAuthStatus();
      if (user) {
        setUser(user);
      } else if (!isExemptPath()) {
        navigate("/login");
      }
      setLoading(false);
    };

    checkSessionAndFetchUser();

    setTimeout(() => {
      if (!isExemptPath()) {
        navigate("/login");
      }
    }, fallbackExpirationTime);
  }, [navigate, location.pathname]);

  return (
    <AuthContext.Provider value={{ user, setUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
