import React, { useState, useEffect, useContext, useRef } from "react";
import { isEqual } from "lodash";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import axios from "utils/axios";
import Error from "components/ui/Error";
import Button from "components/ui/Button";
import { useForm } from "react-hook-form";
import { MdOutlineEdit } from "react-icons/md";
import { HiChevronDown } from "react-icons/hi2";
import GoogleMaps from "components/GoogleMaps";
import GoogleChart from "components/GoogleChart";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EditableField from "components/ui/EditableField";
import DeleteField from "components/ui/DeleteField";
import Tooltip from "components/ui/Tooltip";
import arrow from "assets/images/arrow.png";
import GooglePieChart from "components/GooglePieChart";
import { AuthContext } from "context/AuthContext";
import Modal from "components/ui/Modal";
import propertyImg1 from "assets/images/property-img-1.jpg";
import propertyImg2 from "assets/images/property-img-2.png";
import propertyImg3 from "assets/images/property-img-3.jpg";
import propertyImg4 from "assets/images/property-img-4.jpg";
import propertyImg5 from "assets/images/property-img-5.jpg";
import propertyImg6 from "assets/images/property-img-6.jpg";
import propertyImg7 from "assets/images/property-img-7.jpg";
import propertyImg8 from "assets/images/property-img-8.jpg";
import propertyImg9 from "assets/images/property-img-9.jpg";
import propertyImg10 from "assets/images/property-img-10.jpg";
import propertyImg11 from "assets/images/property-img-11.jpg";
import propertyImg12 from "assets/images/property-img-12.jpg";
import processChainImg1 from "assets/images/process-chain-1.jpg";
import processChainImg2 from "assets/images/process-chain-2.jpg";
import processChainImg3 from "assets/images/process-chain-3.jpg";
import processChainImg4 from "assets/images/process-chain-4.jpg";
import processExampleImg from "assets/images/process-example.png";
import "./PriceProcess.css";
import RecommendedSalesProcess from "./RSP";
import MarketingPrice from "components/ui/MarketingPrice";

const formatCurrency = (value) => {
  if (value === undefined || value === null) return "N/A";
  return "$" + new Intl.NumberFormat().format(value);
};

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  adaptiveHeight: true,
  arrows: true,
  slidesToShow: 1, // Show only 2 slides if they are large in width
  slidesToScroll: 1,
  variableWidth: true, // Allows variable width for slides
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      },
    },
  ],
};

// const RecentAreaSoldProcess = ({ recentAreaSoldProcess }) => {
//   const [expandedRow, setExpandedRow] = useState(null);

//   const toggleRow = (index) => {
//     setExpandedRow(expandedRow === index ? null : index); // Toggle row
//   };

//   return (
//     <>
//       {recentAreaSoldProcess && recentAreaSoldProcess.length > 0 && (
//         <div className="w-full space-y-8 text-center">
//           <h4 className="text-center">RECENT AREA SOLD PROCESS</h4>
//           <div className="overflow-x-auto">
//             <table className="w-full text-sm text-center border-collapse border border-mediumgray mx-auto">
//               <thead>
//                 <tr className="border-b border-mediumgray">
//                   <th className="hidden sm:table-cell py-2 px-3 border-r border-mediumgray"></th>
//                   <th className="py-2 px-3 border-r border-mediumgray min-w-[120px]">
//                     Address
//                   </th>
//                   <th className="hidden sm:table-cell py-2 px-3 border-r border-mediumgray">
//                     Agency
//                   </th>
//                   <th className="py-2 px-3 border-r border-mediumgray min-w-[100px]">
//                     Process
//                   </th>
//                   <th className="py-2 px-3 border-r border-mediumgray min-w-[75px]">
//                     Sale Price
//                   </th>
//                   <th className="py-2 px-3">Days on Market</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {recentAreaSoldProcess.map((property, index) => (
//                   <React.Fragment key={index}>
//                     <tr
//                       className={`border-b border-mediumgray cursor-pointer hover:bg-lightgray ${
//                         expandedRow === index ? "bg-lightgray" : ""
//                       }`}
//                       onClick={() => toggleRow(index)}
//                     >
//                       <td className="hidden sm:table-cell py-2 px-3 border-r border-mediumgray">
//                         {index + 1}
//                       </td>
//                       <td className="py-2 px-3 border-r border-mediumgray">
//                         {property.address}
//                       </td>
//                       <td className="hidden sm:table-cell py-2 px-3 border-r border-mediumgray">
//                         {property.listingHistory?.listings?.[0]?.agencies?.[0]
//                           ?.name || "N/A"}
//                       </td>
//                       <td className="py-2 px-3 border-r border-mediumgray">
//                         {property.beleefSaleProcess || "N/A"}
//                       </td>
//                       <td className="py-2 px-3 border-r border-mediumgray ">
//                         {property?.saleHistory?.sales?.[0]?.price?.value
//                           ? `$${property.saleHistory?.sales?.[0]?.price.value.toLocaleString()}`
//                           : "N/A"}
//                       </td>
//                       <td className="py-2 px-3 w-24">
//                         {property?.saleHistory?.sales?.[0]?.listingHistory
//                           ?.daysToSell + 1 || "N/A"}
//                       </td>
//                     </tr>

//                     {expandedRow === index && (
//                       <tr className="border-b border-mediumgray ">
//                         <td colSpan="6" className="border-t border-mediumgray">
//                           <div className="p-4">
//                             <table className="min-w-full table-auto border-collapse border border-gray-300">
//                               <thead>
//                                 <tr>
//                                   <th className="px-3 py-2 border border-gray-300">
//                                     Date
//                                   </th>
//                                   <th className="px-3 py-2 border border-gray-300">
//                                     Listing Price
//                                   </th>
//                                   <th className="px-3 py-2 border border-gray-300">
//                                     Agency Name
//                                   </th>
//                                   <th className="px-3 py-2 border border-gray-300">
//                                     Agent Name
//                                   </th>
//                                   <th className="px-3 py-2 border border-gray-300">
//                                     DOM
//                                   </th>
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 {property?.listingHistory?.listings.map(
//                                   (listing, index) => (
//                                     <tr
//                                       key={index}
//                                       className="hover:bg-lightgray"
//                                     >
//                                       <td className="px-3 py-2 border border-gray-300">
//                                         {listing.startDate?.display || "N/A"}
//                                       </td>
//                                       <td className="px-3 py-2 border border-gray-300">
//                                         {listing.price?.display || "N/A"}
//                                       </td>

//                                       <td className="px-3 py-2 border border-gray-300">
//                                         {listing.agencies?.[0]?.name || "N/A"}
//                                       </td>
//                                       <td className="px-3 py-2 border border-gray-300">
//                                         {listing.agents?.[0]?.name || "N/A"}
//                                       </td>
//                                       <td className="px-3 py-2 border border-gray-300">
//                                         {listing.listingHistory?.daysOnMarket ||
//                                           "N/A"}
//                                       </td>
//                                     </tr>
//                                   )
//                                 )}
//                               </tbody>
//                             </table>
//                           </div>
//                         </td>
//                       </tr>
//                     )}
//                   </React.Fragment>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//           <p className="text-start text-xs">*Last 6 months sales</p>
//         </div>
//       )}
//     </>
//   );
// };

const RecentAreaSoldProcess = ({ recentAreaSoldProcess }) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [filterMonths, setFilterMonths] = useState(12); // Default is 12 months

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index); // Toggle row
  };

  // Calculate the date for the selected months ago
  const calculateMonthsAgo = (months) => {
    const date = new Date();
    date.setMonth(date.getMonth() - months);
    return date;
  };

  const twelveMonthsAgo = calculateMonthsAgo(filterMonths); // Based on selected months
  const parseDate = (dateString) => new Date(dateString);

  const filteredProperties = recentAreaSoldProcess.filter((property) => {
    const saleDateValue = property?.saleHistory?.sales?.[0]?.saleDate?.value;
    if (saleDateValue) {
      const saleDate = parseDate(saleDateValue); // Convert string to Date object
      return saleDate >= twelveMonthsAgo; // Perform the comparison
    }
    return false; // Exclude properties without a valid saleDate.value
  });

  return (
    <>
      {recentAreaSoldProcess && recentAreaSoldProcess.length > 0 && (
        <div className="w-full space-y-8 text-center">
          <div>
            <h4 className="text-center">RECENT AREA SOLD PROCESS</h4>
            <div className="flex items-center justify-end">
              {/* Select box for choosing months */}
              <label className="mr-2">Show sales from the last:</label>
              <select
                value={filterMonths}
                onChange={(e) => setFilterMonths(Number(e.target.value))}
                className="form-select border border-mediumgray w-auto min-w-[100px] py-1 px-2 min-h-[20px]"
              >
                <option value={6}>6 months</option>
                <option value={12}>12 months</option>
              </select>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full text-sm text-center border-collapse border  mx-auto">
              <thead>
                <tr className="border-b ">
                  <th className="hidden sm:table-cell py-2 px-3 border-r "></th>
                  <th className="py-2 px-3 border-r  min-w-[120px]">Address</th>
                  <th className="hidden sm:table-cell py-2 px-3 border-r ">
                    Agency
                  </th>
                  <th className="py-2 px-3 border-r  min-w-[100px]">Process</th>
                  <th className="py-2 px-3 border-r  min-w-[75px]">
                    Sale Price
                  </th>
                  <th className="py-2 px-3">Days on Market</th>
                </tr>
              </thead>
              <tbody>
                {filteredProperties.map((property, index) => (
                  <React.Fragment key={index}>
                    <tr
                      className={`border-b  cursor-pointer hover:bg-lightgray ${
                        expandedRow === index ? "bg-mediumgray" : ""
                      }`}
                      onClick={() => toggleRow(index)}
                    >
                      <td className="hidden sm:table-cell py-2 px-3 border-r ">
                        {index + 1}
                      </td>
                      <td className="py-2 px-3 border-r ">
                        {property.address}
                      </td>
                      <td className="hidden sm:table-cell py-2 px-3 border-r ">
                        {property.listingHistory?.listings?.[0]?.agencies?.[0]
                          ?.name || "N/A"}
                      </td>
                      <td className="py-2 px-3 border-r ">
                        {property.beleefSaleProcess || "N/A"}
                      </td>
                      <td className="py-2 px-3 border-r  ">
                        {property?.saleHistory?.sales?.[0]?.price?.value
                          ? `$${property.saleHistory?.sales?.[0]?.price.value.toLocaleString()}`
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3 w-24">
                        {property?.saleHistory?.sales?.[0]?.listingHistory
                          ?.daysToSell + 1 || "N/A"}
                      </td>
                    </tr>

                    {expandedRow === index && (
                      <tr className="border-b ">
                        <td colSpan="6" className="border-t ">
                          <div className="p-4">
                            <table className="bg-lightergray min-w-full table-auto border-collapse border">
                              <thead>
                                <tr>
                                  <th className="px-3 py-2 border">Date</th>
                                  <th className="px-3 py-2 border">
                                    Listing Price
                                  </th>
                                  <th className="px-3 py-2 border">
                                    Agency Name
                                  </th>
                                  <th className="px-3 py-2 border">
                                    Agent Name
                                  </th>
                                  <th className="px-3 py-2 border">DOM</th>
                                </tr>
                              </thead>
                              <tbody>
                                {property?.listingHistory?.listings.map(
                                  (listing, index) => (
                                    <tr
                                      key={index}
                                      className="hover:bg-darkgray"
                                    >
                                      <td className="px-3 py-2 border">
                                        {listing.startDate?.display || "N/A"}
                                      </td>
                                      <td className="px-3 py-2 border">
                                        {listing.price?.display || "N/A"}
                                      </td>

                                      <td className="px-3 py-2 border">
                                        {listing.agencies?.[0]?.name || "N/A"}
                                      </td>
                                      <td className="px-3 py-2 border">
                                        {listing.agents?.[0]?.name || "N/A"}
                                      </td>
                                      <td className="px-3 py-2 border">
                                        {listing.listingHistory?.daysOnMarket ||
                                          "N/A"}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <p className="text-start text-xs">
            *Last {filterMonths} months sales
          </p>
        </div>
      )}
    </>
  );
};

const ProcessChain = ({ property }) => {
  const [chainStep, setChainStep] = useState(0);
  const [isProcessChain, setIsProcessChain] = useState(false);
  const [steps, setSteps] = useState(property.processChain);

  const goToChainStep = async (index) => {
    if (index === 0 || steps[index - 1].selected === true) {
      setChainStep(index);
      setIsProcessChain(true);
    }
    if (index === 6) {
      const updatedSteps = [...steps];
      updatedSteps[6].selected = true;
      setSteps(updatedSteps);

      try {
        await axios.put("/userProperty", {
          address: property.address,
          processChain: steps,
        });
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const selectOption = (stepIndex, isSelected) => {
    const updatedSteps = [...steps];
    updatedSteps[stepIndex].selected = isSelected;
    setSteps(updatedSteps);

    if (isSelected) {
      if (stepIndex < steps.length - 1) {
        setChainStep(stepIndex + 1);
      } else {
        setIsProcessChain(false);
      }
    } else {
      resetFollowingSteps(stepIndex);
      setChainStep(stepIndex);
    }
    setIsProcessChain(false);
  };

  const resetFollowingSteps = async (fromIndex) => {
    const updatedSteps = [...steps];
    for (let i = fromIndex + 1; i < updatedSteps.length; i++) {
      updatedSteps[i].selected = null;
    }
    setSteps(updatedSteps);
    await axios.put("/userProperty", {
      address: property.address,
      processChain: [
        { label: "1", name: "Views", selected: null },
        { label: "2", name: "Enquiry", selected: null },
        { label: "3", name: "Inspection", selected: null },
        { label: "4", name: "Offers", selected: null },
        { label: "5", name: "Close Feedback", selected: null },
        { label: "6", name: "Vendor Acceptance", selected: null },
        { label: "7", name: "Sold", selected: null },
      ],
    });
  };

  return (
    <div className="w-full space-y-8">
      <h4>THE PROCESS CHAIN</h4>
      <div className="w-full flex overflow-x-auto box-scrollbar">
        {steps.map((step, index) => (
          <div key={index} className="flex items-center">
            <div>
              <div
                className="flex items-center justify-center border-4 chain border-gray-300 bg-white text-gray-500 cursor-pointer"
                onClick={() => goToChainStep(index)}
              >
                {step.selected === true && "✔️"}
                {step.selected === false && "❌"}
                {step.selected === null && ""} {/* Initial state */}
              </div>
              <p className="mt-2 mb-0 min-h-[32px] max-w-[100px] text-xs">
                {step.name}
              </p>
            </div>
            {index < steps.length - 1 && (
              <div className="chain-stick bg-gray-300"></div>
            )}
          </div>
        ))}
      </div>

      <Modal
        isOpen={isProcessChain}
        onClose={() => setIsProcessChain(false)}
        title=""
      >
        {chainStep === 0 && (
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <img src={processChainImg1} class="max-w-[300px] h-auto" />
              <button
                class="black-button"
                onClick={() => selectOption(0, true)}
              >
                Select
              </button>
            </div>
            <div class="flex flex-col gap-4">
              <img src={processChainImg2} class="max-w-[300px] h-auto" />
              <button
                class="black-button"
                onClick={() => selectOption(0, false)}
              >
                Select
              </button>
            </div>
          </div>
        )}

        {chainStep === 1 && (
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>ATTENDANCE</p>
                <p>Automated open home message</p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(1, true)}
              >
                Select
              </button>
            </div>
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>NO ATTENDANCE</p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(1, false)}
              >
                Select
              </button>
            </div>
          </div>
        )}

        {chainStep === 2 && (
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>CORRECT PRICE GUIDE</p>
                <p>
                  Automated message with guide. “We will have a more accurate
                  indication on price once we receive buyer feedback”.
                </p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(2, true)}
              >
                Select
              </button>
            </div>
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>PRICE GUIDE TOO HIGH</p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(2, false)}
              >
                Select
              </button>
            </div>
          </div>
        )}

        {chainStep === 3 && (
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <img src={processChainImg3} class="max-w-[300px] h-auto" />
              <button
                class="black-button"
                onClick={() => selectOption(3, true)}
              >
                Select
              </button>
            </div>
            <div class="flex flex-col gap-4">
              <img src={processChainImg4} class="max-w-[300px] h-auto" />
              <button
                class="black-button"
                onClick={() => selectOption(3, false)}
              >
                Select
              </button>
            </div>
          </div>
        )}

        {chainStep === 4 && (
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>AUTOMATED MESSAGE</p>
                <p>Tender Process</p>
                <p>
                  “Ausrealty has received multiple interests in the property at
                  ‘address’ and advises all parties to submit their best and
                  final offers, including terms, by ‘date’ via text to ‘agent’
                  at ‘mobile’. Contracts will be exchanged without further
                  notice. For FAQs, visit the provided link. Today is the final
                  opportunity to secure the home, so submit your absolute best
                  offer to ensure fairness”.
                </p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(4, true)}
              >
                Select
              </button>
            </div>
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>AUTOMATED MESSAGE</p>
                <p>Other First Offer</p>
                <p>
                  “To buy this property before auction, your offer must be $2
                  million. The auction guide price is $1.7 million, but offers
                  at this price can only be considered at auction.”
                </p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(4, false)}
              >
                Select
              </button>
            </div>
          </div>
        )}

        {chainStep === 5 && (
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>VENDOR ACCEPTSE</p>
                <p>Maximum Price e.g. $1.9m</p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(5, true)}
              >
                Select
              </button>
            </div>
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>VENDOR DECLINES</p>
                <div class="overflow-x-auto">
                  <table class="min-w-full border border-black">
                    <tbody>
                      <tr>
                        <td class="border border-black px-4 py-2">Vendor</td>
                        <td class="border border-black px-4 py-2">3m</td>
                      </tr>
                      <tr>
                        <td class="border border-black px-4 py-2">1</td>
                        <td class="border border-black px-4 py-2">1.9m</td>
                      </tr>
                      <tr>
                        <td class="border border-black px-4 py-2">1.8m</td>
                        <td class="border border-black px-4 py-2">Offer</td>
                      </tr>
                      <tr>
                        <td class="border border-black px-4 py-2">1.6m</td>
                        <td class="border border-black px-4 py-2">Offer</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(5, false)}
              >
                Select
              </button>
            </div>
          </div>
        )}

        {chainStep === 6 && (
          <div class="flex flex-col gap-8">
            <p>Congratulations</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

const FinishesSelector = ({
  register,
  errors,
  selectedValue,
  onChange,
  onEdit,
}) => {
  const finishesData = [
    {
      label: "High-end finishes",
      value: "High-end finishes",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/finishesGuide/high-end.png",
    },
    {
      label: "Updated",
      value: "Updated",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/finishesGuide/updated.png",
    },
    {
      label: "Original",
      value: "Original",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/finishesGuide/original.png",
    },
  ];

  useEffect(() => {
    register("finishes", { required: "Finishes selection is required" });
  }, [register]);

  return (
    <div>
      <label className="form-label">Select Finishes</label>
      {selectedValue ? (
        <div className="flex items-center justify-between form-input border border-mediumgray p-2">
          <span>{selectedValue}</span>
          <button type="button" onClick={onEdit} className="text-darkergray">
            <MdOutlineEdit />
          </button>
        </div>
      ) : (
        <div className="flex overflow-x-auto box-scrollbar gap-4">
          {finishesData.map((finish) => (
            <div
              key={finish.value}
              className="cursor-pointer rounded mr-4 transition"
              onClick={() => onChange(finish.value)}
            >
              <img
                src={finish.imgSrc}
                alt={finish.label}
                className="w-full h-auto min-w-[250px] max-h-[256px] object-cover"
              />
              <span className="block text-sm text-center mt-2">
                {finish.label}
              </span>
            </div>
          ))}
        </div>
      )}
      {errors.finishes && (
        <span className="form-error-message">{errors.finishes.message}</span>
      )}
    </div>
  );
};

const WaterViewsSelector = ({
  register,
  errors,
  selectedValue,
  onChange,
  onEdit,
}) => {
  const waterViewsData = [
    {
      label: "Deep waterfront with jetty",
      value: "Deep waterfront with jetty",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/waterViews/deep-waterfront-with-jetty.jpg",
    },
    {
      label: "Water views",
      value: "Water views",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/waterViews/water-views.jpg",
    },
    {
      label: "Tidal waterfront with jetty",
      value: "Tidal waterfront with jetty",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/waterViews/tidal-waterfront-with-jetty.jpg",
    },
    {
      label: "Waterfront reserve",
      value: "Waterfront reserve",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/waterViews/waterfront-reserve.jpg",
    },
  ];

  useEffect(() => {
    register("waterViews", { required: "Waterviews selection is required" });
  }, [register]);

  const handleNoCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      onChange("No");
    } else {
      onChange("");
    }
  };

  return (
    <div>
      <label className="form-label">Select Water Aspect</label>

      {/* Display selected value with Edit button if a selection is made */}
      {selectedValue ? (
        <div className="flex items-center justify-between form-input border border-mediumgray p-2">
          <span>{selectedValue}</span>
          <button type="button" onClick={onEdit} className="text-darkergray">
            <MdOutlineEdit />
          </button>
        </div>
      ) : (
        // Display options when no selection is made
        <>
          {/* "No" Checkbox */}
          <label className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={selectedValue === "No"}
              onChange={handleNoCheckboxChange}
              className="mr-2"
            />
            No
          </label>

          {/* Display options if "No" is not selected */}
          {selectedValue !== "No" && (
            <div className="flex overflow-x-auto box-scrollbar gap-4">
              {waterViewsData.map((waterView) => (
                <div
                  key={waterView.value}
                  className="cursor-pointer rounded mr-4 transition"
                  onClick={() => onChange(waterView.value)}
                >
                  <img
                    src={waterView.imgSrc}
                    alt={waterView.label}
                    className="w-full h-auto min-w-[250px] max-h-[256px] object-cover"
                  />
                  <span className="block text-sm text-center mt-2">
                    {waterView.label}
                  </span>
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {/* Display errors if any */}
      {errors.waterViews && (
        <span className="form-error-message">{errors.waterViews.message}</span>
      )}
    </div>
  );
};

const TopographyDropdown = ({
  selectedValues = [], // Ensure selectedValues is an array
  onToggleOption,
  isOpen,
  toggleDropdown,
  errors,
  register,
  trigger, // add trigger to manually validate
}) => {
  const dropdownRef = useRef(null);

  // useEffect(() => {
  //   register("topography", {
  //     validate: (value) =>
  //       value.length > 0 || "At least one option must be selected",
  //   });
  // }, [register]);

  useEffect(() => {
    register("topography", {
      validate: (value) => {
        if (!Array.isArray(value) || value.length === 0) {
          return "At least one option must be selected"; // Error if no selection
        }
        return true; // No error if validation passes
      },
    });
  }, [register]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropdown(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleDropdown]);

  const handleToggleOption = (option) => {
    onToggleOption(option);
    trigger("topography"); // Trigger validation after toggling an option
  };

  return (
    <div ref={dropdownRef} className="relative">
      <div
        className="flex items-center justify-between form-select p-2 border border-mediumgray cursor-pointer"
        onClick={() => toggleDropdown(!isOpen)}
      >
        <div className="truncate">
          {selectedValues?.length > 0
            ? selectedValues.join(", ")
            : "Select options..."}
        </div>

        <HiChevronDown />
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full bg-white border border-mediumgray">
          {[
            "High side",
            "Level block",
            "Low side",
            "Irregular block",
            "Unusable land",
          ].map((option) => (
            <div
              key={option}
              className="flex items-center text-xs p-2 cursor-pointer hover:bg-lightgray"
              onClick={() => handleToggleOption(option)}
            >
              <input
                type="checkbox"
                checked={selectedValues?.includes(option) || false}
                readOnly
                className="mr-2"
              />

              {option}
            </div>
          ))}
        </div>
      )}
      {errors.topography && (
        <span className="form-error-message">{errors.topography.message}</span>
      )}
    </div>
  );
};

const PropertyForm = ({ property, onSubmitForm }) => {
  const { address, waterViews } = property || {};
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(property);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    reset,
  } = useForm({
    defaultValues: formData,
    // Ensure that fields are not unregistered when they are removed from the UI
    shouldUnregister: false,
  });

  useEffect(() => {
    setFormData(property);
    reset(property); // Reset form with new property data
  }, [property, reset]);

  const [editMode, setEditMode] = useState(false);
  const [topographyOpen, setTopographyOpen] = useState(false);

  const prevStep = () => {
    setStep(step - 1);
    reset(formData);
  };

  const selectedFinish = watch("finishes");
  const selectedWaterView = watch("waterViews");
  const selectedTopography = watch("topography");
  const propertyType = watch("propertyType"); // Watch propertyType

  const onSubmit = (data) => {
    const updatedData = { ...formData, ...data };

    setFormData(updatedData);

    if (step === 3) {
      // Call the parent function and pass the updated formData
      onSubmitForm(updatedData);
    } else {
      setStep(step + 1);
    }
  };

  const handleSelectFinish = (value) => {
    setValue("finishes", value);
    trigger("finishes"); // Trigger validation after setting the value
    setEditMode(false);
  };

  const handleEditFinish = () => {
    setEditMode(true);
    setValue("finishes", ""); // Clear the value when editing
    trigger("finishes"); // Trigger validation after setting the value
  };

  const handleSelectWaterView = (value) => {
    setValue("waterViews", value);
    trigger("waterViews"); // Trigger validation after setting the value
    setEditMode(false);
  };

  const handleEditWaterView = () => {
    setEditMode(true);
    setValue("waterViews", ""); // Clear the value when editing
    trigger("waterViews"); // Trigger validation after setting the value
  };

  const handleToggleTopographyOption = (option) => {
    let newSelectedTopography = [...(selectedTopography || [])];
    if (option === "High side") {
      if (selectedTopography?.includes("Low side")) {
        // Remove "Low side" if "High side" is selected
        newSelectedTopography = selectedTopography.filter(
          (item) => item !== "Low side"
        );
      }
    } else if (option === "Low side") {
      if (selectedTopography?.includes("High side")) {
        // Remove "High side" if "Low side" is selected
        newSelectedTopography = selectedTopography.filter(
          (item) => item !== "High side"
        );
      }
    }

    // Toggle the current option
    if (newSelectedTopography?.includes(option)) {
      newSelectedTopography = newSelectedTopography.filter(
        (item) => item !== option
      );
    } else {
      newSelectedTopography.push(option);
    }

    setValue("topography", newSelectedTopography);
    trigger("topography"); // Trigger validation after changing value
  };

  const toggleTopographyDropdown = () => {
    setTopographyOpen(!topographyOpen);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      {step === 1 && (
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6">
            <label className="form-label">Property Address</label>
            <input
              type="text"
              className={`form-input border ${
                errors.propertyAddress ? "border-red-500" : "border-mediumgray"
              }`}
              {...register("propertyAddress", {
                required: false,
              })}
              value={address}
              disabled
              readOnly
            />
            {errors.propertyAddress && (
              <span className="form-error-message">
                {errors.propertyAddress.message}
              </span>
            )}
          </div>

          <div className="col-span-12 sm:col-span-6 relative">
            <label className="form-label">Property Type</label>
            <select
              className={`form-select border ${
                errors.propertyType ? "border-red-500" : "border-mediumgray"
              }`}
              {...register("propertyType", {
                required: "Property Type is required",
              })}
            >
              <option value="">Select Property Type</option>
              {[
                "ApartmentUnitFlat",
                "Duplex",
                "House",
                "Terrace",
                "Townhouse",
                "VacantLand",
                "Villa",
              ].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.propertyType && (
              <span className="form-error-message">
                {errors.propertyType.message}
              </span>
            )}
          </div>

          {/* Conditionally render Land Area and Frontage */}
          {propertyType !== "ApartmentUnitFlat" && (
            <>
              <div className="col-span-6">
                <label className="form-label">Land Area</label>
                <input
                  type="number"
                  step="any"
                  className={`form-input border ${
                    errors.landArea ? "border-red-500" : "border-mediumgray"
                  }`}
                  {...register("landArea", {
                    required:
                      propertyType !== "ApartmentUnitFlat"
                        ? "Land Area is required"
                        : false,
                  })}
                />
                {errors.landArea && (
                  <span className="form-error-message">
                    {errors.landArea.message}
                  </span>
                )}
              </div>

              <div className="col-span-6">
                <label className="form-label">Frontage</label>
                <input
                  type="number"
                  step="any" // Allows decimal values
                  className={`form-input border ${
                    errors.frontage ? "border-red-500" : "border-mediumgray"
                  }`}
                  {...register("frontage")}
                />
                {errors.frontage && (
                  <span className="form-error-message">
                    {errors.frontage.message}
                  </span>
                )}
              </div>
            </>
          )}

          <div className="col-span-6 sm:col-span-4">
            <label className="form-label">Bedrooms</label>
            <input
              type="number"
              className={`form-input border ${
                errors.bedrooms ? "border-red-500" : "border-mediumgray"
              }`}
              {...register("bedrooms", {
                required: "Number of Beds is required",
              })}
              min={0}
            />
            {errors.bedrooms && (
              <span className="form-error-message">
                {errors.bedrooms.message}
              </span>
            )}
          </div>

          <div className="col-span-6 sm:col-span-4">
            <label className="form-label">Bathrooms</label>
            <input
              type="number"
              className={`form-input border ${
                errors.bathrooms ? "border-red-500" : "border-mediumgray"
              }`}
              {...register("bathrooms", {
                required: "Number of Baths is required",
              })}
              min={0}
            />
            {errors.bathrooms && (
              <span className="form-error-message">
                {errors.bathrooms.message}
              </span>
            )}
          </div>

          <div className="col-span-6 sm:col-span-4">
            <label className="form-label">Car Spaces</label>
            <input
              type="number"
              className={`form-input border ${
                errors.carspaces ? "border-red-500" : "border-mediumgray"
              }`}
              {...register("carspaces", {
                required: "Number of Car Spaces is required",
              })}
              min={0}
            />
            {errors.carspaces && (
              <span className="form-error-message">
                {errors.carspaces.message}
              </span>
            )}
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <FinishesSelector
              register={register}
              errors={errors}
              selectedValue={editMode ? null : selectedFinish}
              onChange={handleSelectFinish}
              onEdit={handleEditFinish}
            />
          </div>

          <div className="col-span-12">
            <WaterViewsSelector
              register={register}
              errors={errors}
              selectedValue={editMode ? null : selectedWaterView}
              onChange={handleSelectWaterView}
              onEdit={handleEditWaterView}
            />
          </div>

          {/* Conditionally render Topography */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-12">
              <label className="form-label">Topography</label>
              <TopographyDropdown
                selectedValues={selectedTopography}
                onToggleOption={handleToggleTopographyOption}
                isOpen={topographyOpen}
                toggleDropdown={toggleTopographyDropdown}
                errors={errors}
                register={register}
                trigger={trigger}
              />
            </div>
          )}
        </div>
      )}

      {step === 3 && (
        <div className="grid grid-cols-12 gap-4">
          {/* Conditionally render Build Construction Type */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-6">
              <label className="form-label">Build Construction Type</label>
              <select
                className={`form-input border ${
                  errors.buildType ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("buildType", {
                  required:
                    propertyType !== "ApartmentUnitFlat"
                      ? "Build Construction Type is required"
                      : false,
                })}
              >
                <option value="">Select Construction Type</option>
                <option value="1 storey">1 storey</option>
                <option value="2 storey">2 storey</option>
                <option value="3 story">3 story</option>
                <option value="4+ storey">4+ storey</option>
              </select>
              {errors.buildType && (
                <span className="form-error-message">
                  {errors.buildType.message}
                </span>
              )}
            </div>
          )}

          {/* Conditionally render Granny Flat */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-6">
              <label className="form-label">Granny Flat</label>
              <select
                className={`form-select border ${
                  errors.grannyFlat ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("grannyFlat", {
                  required:
                    propertyType !== "ApartmentUnitFlat"
                      ? "Granny Flat selection is required"
                      : false,
                })}
              >
                <option value="">Select Granny Flat</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {errors.grannyFlat && (
                <span className="form-error-message">
                  {errors.grannyFlat.message}
                </span>
              )}
            </div>
          )}

          {/* Conditionally render Wall Material */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-6">
              <label className="form-label">Wall Material</label>
              <select
                className={`form-input border ${
                  errors.wallMaterial ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("wallMaterial", {
                  required:
                    propertyType !== "ApartmentUnitFlat"
                      ? "Wall Material is required"
                      : false,
                })}
              >
                <option value="">Select Wall Material</option>
                <option value="Brick">Brick</option>
                <option value="Double brick">Double brick</option>
                <option value="Clad">Clad</option>
                <option value="Fibro">Fibro</option>
                <option value="Hebel">Hebel</option>
              </select>
              {errors.wallMaterial && (
                <span className="form-error-message">
                  {errors.wallMaterial.message}
                </span>
              )}
            </div>
          )}

          {/* Conditionally render Pool */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-6">
              <label className="form-label">Pool</label>
              <select
                className={`form-select border ${
                  errors.pool ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("pool", {
                  required:
                    propertyType !== "ApartmentUnitFlat"
                      ? "Pool selection is required"
                      : false,
                })}
              >
                <option value="">Select Pool</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {errors.pool && (
                <span className="form-error-message">
                  {errors.pool.message}
                </span>
              )}
            </div>
          )}

          {/* Conditionally render Tennis Court */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-6">
              <label className="form-label">Tennis Court</label>
              <select
                className={`form-select border ${
                  errors.tennisCourt ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("tennisCourt", {
                  required:
                    propertyType !== "ApartmentUnitFlat"
                      ? "Tennis Court selection is required"
                      : false,
                })}
              >
                <option value="">Select Tennis Court</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {errors.tennisCourt && (
                <span className="form-error-message">
                  {errors.tennisCourt.message}
                </span>
              )}
            </div>
          )}

          {/* Conditionally render Street Traffic */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-6">
              <label className="form-label">Street Traffic</label>
              <select
                className={`form-select border ${
                  errors.streetTraffic ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("streetTraffic", {
                  required:
                    propertyType !== "ApartmentUnitFlat"
                      ? "Street Traffic selection is required"
                      : false,
                })}
              >
                <option value="">Select Street Traffic</option>
                <option value="Low traffic">Low traffic</option>
                <option value="Moderate traffic">Moderate traffic</option>
                <option value="High traffic">High traffic</option>
              </select>
              {errors.streetTraffic && (
                <span className="form-error-message">
                  {errors.streetTraffic.message}
                </span>
              )}
            </div>
          )}

          {/* Conditionally render Development Potential */}
          {propertyType !== "ApartmentUnitFlat" && (
            <div className="col-span-12 sm:col-span-6">
              <label className="form-label">Development Potential</label>
              <select
                className={`form-select border ${
                  errors.developmentPotential
                    ? "border-red-500"
                    : "border-mediumgray"
                }`}
                {...register("developmentPotential")}
              >
                <option value="">Select Development Potential</option>
                <option value="Childcare">Childcare</option>
                <option value="Duplex site">Duplex site</option>
                <option value="Townhouse site">Townhouse site</option>
                <option value="Unit site">Unit site</option>
              </select>
              {errors.developmentPotential && (
                <span className="form-error-message">
                  {errors.developmentPotential.message}
                </span>
              )}
            </div>
          )}

          <div className="col-span-12 sm:col-span-6">
            <label className="form-label">Additional Information</label>
            <textarea
              className={`form-textarea border ${
                errors.additionalInformation
                  ? "border-red-500"
                  : "border-mediumgray"
              }`}
              {...register("additionalInformation")}
              rows={3}
            />
          </div>
        </div>
      )}

      <div className="flex justify-end gap-4 mt-6">
        {step > 1 && (
          <Button type="button" onClick={prevStep} className="gray-button">
            Previous
          </Button>
        )}

        <Button type="submit" className="black-button">
          {step === 3 ? "Save" : "Next"}
        </Button>
      </div>
    </form>
  );
};

const PropertyResult = ({ property, onEdit }) => {
  const handleDelete = async (fieldPath) => {
    try {
      const deleteData = {
        fieldPath, // This will be something like `soldProperties[0]`
        remove: true, // Mark that this field is to be removed
      };

      await axios.put("/userProperty", {
        _id: property._id,
        address: property.address,
        deleteData,
      });
    } catch (error) {
      console.error("Error deleting post list item:", error);
    }
  };

  const [loading, setLoading] = useState(false);

  const { user } = useContext(AuthContext);

  const [logicalPrice, setLogicalPrice] = useState(null);
  const [microPockets, setMicroPockets] = useState(null);
  const [saleProperties, setSaleProperties] = useState([]);
  const [soldProperties, setSoldProperties] = useState([]);
  const [areaDynamics, setAreaDynamics] = useState(null);
  const [logicalReasoning, setLogicalReasoning] = useState(null);
  const [engagedPurchaser, setEngagedPurchaser] = useState(null);
  const [pieChartData, setPieChartData] = useState([["Process", "Count"]]);
  const [marketingItems, setMarketingItems] = useState(
    property?.marketingItems
  );
  const [marketingPrice, setMarketingPrice] = useState(
    property?.marketingPrice
  );
  const [recentAreaSoldProcess, setRecentAreaSoldProcess] = useState([]);

  const [duplexProperties, setDuplexProperties] = useState([]);
  const [highEndProperties, setHighEndProperties] = useState([]);
  const [lowEndProperties, setLowEndProperties] = useState([]);
  const [isPropertyClicked, setIsPropertyClicked] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const [recommendedSaleProcess, setRecommendedSaleProcess] = useState(null);

  const handlePropertyClick = (property) => {
    setSelectedProperty(property);
    setIsPropertyClicked(true);
  };

  useEffect(() => {
    if (!property?._id) return; // Ensure property exists before making API calls

    const fetchAreaDynamics = async () => {
      try {
        const response = await axios.get(`/property/suburb/${property.suburb}`);

        setAreaDynamics(response?.data?.data);
      } catch (error) {
        console.error("Error fetching area dynamics:", error);
      }
    };

    const fetchRecommendProperties = async () => {
      try {
        // not a good logic but for now to check if the form is updated
        if (
          property.engagedPurchaser &&
          property.microPockets &&
          property.logicalReasoning &&
          new Date() - new Date(property.updatedAt) > 20 * 1000
        ) {
          setLogicalPrice(property.logicalPrice || null);
          setLogicalReasoning(property.logicalReasoning || null);
          setSaleProperties(property.recommendedSales || []);
          setSoldProperties(property.recommendedSold || []);
          setRecentAreaSoldProcess(property.recentAreaSoldProcess || []);
          setDuplexProperties(property.duplexProperties || []);
          setEngagedPurchaser(property.engagedPurchaser || null);
          setRecommendedSaleProcess(property.recommendedSaleProcess);
          setMicroPockets(property.microPockets);
          setHighEndProperties(property.highEndProperties);
          setLowEndProperties(property.lowEndProperties);
          return;
        }

        const response = await axios.post("/property/recommend", {
          property, // Send the whole property object in the request body
        });

        if (response.data.success) {
          setLogicalPrice(response?.data?.data?.logical?.logicalPrice || null);
          setLogicalReasoning(
            response?.data?.data?.logical?.logicalReasoning || null
          );
          setSaleProperties(response?.data?.data?.recommendedSales || []);
          setSoldProperties(response?.data?.data?.recommendedSold || []);
          setRecentAreaSoldProcess(
            response?.data?.data?.recentAreaSoldProcess || []
          );
          setDuplexProperties(response?.data?.data?.duplexProperties || []);
          setEngagedPurchaser(response?.data?.data?.engagedPurchaser || null);
          setMicroPockets(response?.data?.data?.microPockets || null);
          setRecommendedSaleProcess(
            response?.data?.data?.recommendedSaleProcess
          );
          setHighEndProperties(response?.data?.data?.highEndProperties);
          setLowEndProperties(response?.data?.data?.lowEndProperties);
          const {
            logical,
            recommendedSales,
            recommendedSold,
            recentAreaSoldProcess,
            duplexProperties,
            engagedPurchaser,
            microPockets,
          } = response.data.data;

          await axios.put("/userProperty", {
            address: property.address,
            logicalPrice: logical.logicalPrice,
            logicalReasoning: logical.logicalReasoning,
            recommendedSales: recommendedSales,
            recommendedSold: recommendedSold,
            recentAreaSoldProcess,
            duplexProperties,
            engagedPurchaser,
            microPockets,
          });
        }
      } catch (error) {
        console.error("Error fetching recommended properties:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchBeleefSaleProcess = async () => {
      try {
        const response = await axios.get(
          `/property/beleefSaleProcess/${property.suburb}`
        );
        const apiData = response.data.data;

        // Map the API data to the format required for the chart
        const formattedData = [["Process", "Count"]]; // Initialize with header row
        for (const process in apiData) {
          // Skip "Withdrawn" process
          if (process !== "Withdrawn") {
            formattedData.push([process, apiData[process]]);
          }
        }
        // Update the chart data
        setPieChartData(formattedData);
      } catch (error) {
        console.error("Error fetching beleef sale process:", error);
      }
    };

    const executeFetchFunctions = async () => {
      try {
        setLoading(true);
        await fetchAreaDynamics(); // Waits for this to complete before moving on
        await fetchRecommendProperties(); // Waits for this to complete
        await fetchBeleefSaleProcess(); // Waits for this to complete
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    executeFetchFunctions();

    // Only trigger the useEffect when property._id changes
  }, [property._id]);

  const convertDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  function getAverageValueInUnits(range) {
    // Remove dollar signs and split the range into two numbers
    const [low, high] = range
      .replace(/\$/g, "") // Remove dollar signs
      .split("-") // Split by the hyphen into low and high values
      .map((str) => str.trim()); // Trim spaces in case they exist

    // Function to convert the value based on suffix (M, K, or no suffix)
    const convertToUnits = (value, suffix) => {
      if (suffix === "M") {
        return parseFloat(value) * 1000000; // Convert millions to units
      } else if (suffix === "K") {
        return parseFloat(value) * 1000; // Convert thousands to units
      } else {
        return parseFloat(value); // If no suffix, assume it's already in units
      }
    };

    // Determine if the high value has a suffix (M or K)
    const highSuffix = high.includes("M") ? "M" : high.includes("K") ? "K" : "";

    // Strip suffix from the high value before conversion
    const highValue = high.replace(/[MK]/, "");

    // Convert low and high values to unit form, applying the same suffix to both
    const lowInUnits = convertToUnits(low, highSuffix); // Apply the suffix from high to low
    const highInUnits = convertToUnits(highValue, highSuffix); // Convert high value without suffix

    // Calculate the average
    const average = (lowInUnits + highInUnits) / 2;

    // Return the average in units format, no suffix
    return average;
  }

  // Create dataPoints for GoogleChart
  const dataPoints = [
    [
      property.landArea || 0,
      logicalPrice ? getAverageValueInUnits(logicalPrice) : 0,
      property.address,
    ],
    ...(() => {
      // Get today's date
      const today = new Date();

      // Filter soldProperties based on dateListed within the last 90 days
      const recentSoldProperties = soldProperties.filter(({ property }) => {
        const dateListed = new Date(property.dateListed);
        const diffInDays = (today - dateListed) / (1000 * 60 * 60 * 24); // Convert milliseconds to days
        return diffInDays <= 180 && property.landArea && property.price;
      });

      // If there are more than 5 properties listed in the last 180 days, return them
      if (recentSoldProperties.length > 5) {
        return recentSoldProperties.map(({ property }) => [
          property.landArea,
          property.price,
          property.address,
        ]);
      } else {
        return soldProperties
          .filter(({ property }) => property.landArea && property.price) // Filter properties with both landArea and price
          .map(({ property }) => [
            property.landArea,
            property.price,
            property.address,
          ]);
      }
    })(),
  ];

  const [regenerateLogicalPrice, setRegenerateLogicalPrice] = useState(false);

  const today = new Date();

  const handleSaveMarketingData = async (selectedItems) => {
    await axios.put("/userProperty", {
      address: property.address,
      marketing: selectedItems,
    });
  };

  const handleSave = async (fieldPath, newValue) => {
    try {
      await axios.put("/userProperty", {
        address: property.address,
        [fieldPath]: newValue,
      });

    } catch (error) {
      console.error("Error updating post list:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center overflow-x-hidden">
      <div className="w-full h-[90vh] relative pb-16">
        <video
          autoPlay
          muted
          loop
          preload="metadata"
          playsInline
          webkit-playsinline="true"
          className="w-full h-full object-cover"
        >
          <source
            src="https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/videos/postlist-video.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="w-full max-w-4xl mx-auto flex flex-col items-center justify-center text-center space-y-12 overflow-x-hidden">
        <div className="w-full flex justify-end gap-2">
          <Button className="gray-button" onClick={onEdit} disabled={loading}>
            Edit
          </Button>
        </div>

        <h4>{property.address}</h4>

        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">GET STARTED</span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="mt-4">
            <div className="space-y-8">
              <div class="text-start space-y-2">
                <h4>Assess situation</h4>
                <p>What is your situation?</p>
              </div>
              <div class="text-start space-y-2">
                <h4>Assess timeline</h4>
                <p>What is your timeline?</p>
              </div>
              {microPockets && (
                <Disclosure as="div" className="w-full" defaultOpen={false}>
                  <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
                    <span className="font-medium">The micro pockets</span>
                    <HiChevronDown className="group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2">
                    <div
                      className="engaged-purchaser"
                      dangerouslySetInnerHTML={{ __html: microPockets }}
                    />
                  </DisclosurePanel>
                </Disclosure>
              )}
              <div className="w-full h-full grayscale flex flex-col items-center justify-center">
                <GoogleMaps lat={property.latitude} lon={property.longitude} />
              </div>

              <div class="w-full max-w-lg mx-auto space-y-4">
                <h4>YOUR AREA DYNAMICS</h4>
                {areaDynamics ? (
                  <div className="overflow-x-auto">
                    <table className="w-full text-sm text-start border border-mediumgray border-collapse">
                      <thead>
                        <tr className="bg-mediumgray border border-mediumgray">
                          <th className="py-2 px-3 border border-mediumgray"></th>
                          <th className="py-2 px-3 text-left border border-mediumgray">
                            <i className="fa-solid fa-house mr-2"></i> House
                          </th>
                          <th className="py-2 px-3 text-left border border-mediumgray">
                            <i className="fa-solid fa-building mr-2"></i> Unit
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-b border-mediumgray">
                          <td className="py-2 px-3 border border-mediumgray">
                            Median Sale Price
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.houseStats
                              ? `$${areaDynamics.houseStats.medianSalePrice?.toLocaleString()}`
                              : "N/A"}
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.unitStats
                              ? `$${areaDynamics.unitStats.medianSalePrice?.toLocaleString()}`
                              : "N/A"}
                          </td>
                        </tr>
                        <tr className="border-b border-mediumgray">
                          <td className="py-2 px-3 border border-mediumgray">
                            Annual Sales Volume
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.houseStats
                              ? areaDynamics.houseStats.annualSalesVolume
                              : "N/A"}
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.unitStats
                              ? areaDynamics.unitStats.annualSalesVolume
                              : "N/A"}
                          </td>
                        </tr>
                        <tr className="border-b border-mediumgray">
                          <td className="py-2 px-3 border border-mediumgray">
                            Average Days on Market
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.houseStats
                              ? areaDynamics.houseStats.averageDaysOnMarket
                              : "N/A"}
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.unitStats
                              ? areaDynamics.unitStats.averageDaysOnMarket
                              : "N/A"}
                          </td>
                        </tr>
                        <tr className="border-b border-mediumgray">
                          <td className="py-2 px-3 border border-mediumgray">
                            Suburb Growth
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.houseStats
                              ? areaDynamics.houseStats.suburbGrowth
                              : "N/A"}
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.unitStats
                              ? areaDynamics.unitStats.suburbGrowth
                              : "N/A"}
                          </td>
                        </tr>
                        <tr className="border-b border-mediumgray">
                          <td className="py-2 px-3 border border-mediumgray">
                            High Demand Area
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.houseStats
                              ? areaDynamics.houseStats.highDemandArea
                              : "N/A"}
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.unitStats
                              ? areaDynamics.unitStats.highDemandArea
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <i className="fa-solid fa-spinner animate-spin mr-2"></i>{" "}
                    Loading ...
                  </div>
                )}
              </div>

              <div class="space-y-8 flex flex-col items-center justify-center">
                <h4>MOST LIKELY ENGAGED PURCHASER</h4>

                {engagedPurchaser && (
                  <div
                    className="engaged-purchaser"
                    dangerouslySetInnerHTML={{ __html: engagedPurchaser }}
                  />
                )}
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>

        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">
              STEP 1: LET’S ASSESS LOGICAL PRICE
            </span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="mt-4">
            <div className="space-y-8">
              <div className="space-y-8">
                <h4>LOGICAL PRICE</h4>

                <div className="max-w-sm mx-auto space-y-8 flex flex-col items-center justify-center">
                  <div className="flex flex-row justify-between w-full text-sm">
                    <span>Buyers start logically</span>
                    <span>and finish emotionally</span>
                  </div>
                  <img src={arrow} alt="arrow" />
                </div>

                {/* Price and Info Icon */}
                <div className="flex items-center justify-center gap-4">
                  {!loading ? (
                    <>
                      <span className="font-bold">
                      <EditableField
                value={logicalPrice ? logicalPrice : "N/A"}
                onSave={(newValue) => {
                  handleSave("logicalPrice", newValue); // Call the save function
              setLogicalPrice(newValue)
                }}
              />

   
                      </span>
                      <i
                        class="fas fa-sync-alt cursor-pointer"
                        onClick={() => setRegenerateLogicalPrice(true)}
                      ></i>
                    </>
                  ) : (
                    <i className="fa-solid fa-spinner animate-spin"></i>
                  )}
                  <Tooltip
                    className="w-[250px]"
                    text={<i className="fa fa-info-circle text-xs"></i>}
                    tooltip="This is just a logical estimated price, and is grounded on a comprehensive set of factors including recent local sales, property size, number of bedrooms, the topography of the land, street traffic, recent updates to the property, and various other determinants. The information is sourced from public datasets which, while extensive, might be incomplete or contain inaccuracies, so should not be solely relied upon. For a more precise and accurate estimation of price, we strongly recommend consulting with a licensed real estate agent or a registered valuer. Occasionally, we may send you updates on the property market"
                  />
                </div>

                {/* Logical Reasoning */}
                {logicalReasoning && (
                  <div className="text-start my-1 text-sm">
                    <span className="font-medium">Reasoning:</span>{" "}
                    {logicalReasoning}
                  </div>
                )}

                {/* Property Details */}
                <div className="max-w-md mx-auto space-y-6">
                  {/* Property Image */}
                  {property?.media?.[0]?.url && (
                    <img
                      className="w-full h-auto rounded-lg grayscale"
                      src={property.media[0].url}
                      alt="property"
                    />
                  )}

                  {/* Property Address */}
                  <p className="text-center">{property.address}</p>

                  {/* Property Features */}
                  <div className="flex items-center justify-center gap-4">
                    <div className="text-sm">
                      <i className="fa fa-bed me-2"></i>
                      {property.bedrooms || "N/A"}
                    </div>
                    <div className="text-sm">
                      <i className="fa fa-bath me-2"></i>
                      {property.bathrooms || "N/A"}
                    </div>
                    <div className="text-sm">
                      <i className="fa fa-car me-2"></i>
                      {property.carspaces || "N/A"}
                    </div>
                    <div className="text-sm">{property.propertyType}</div>
                  </div>

                  {/* Sale Details */}
                  <div className="flex justify-between gap-4 text-left text-sm">
                    <div className="flex flex-col">
                      <span className="font-semibold">Sale Price:</span>
                      <span>{formatCurrency(property.price)}</span>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold">Sale Date:</span>
                      <span>
                        {property.dateListed
                          ? convertDate(property.dateListed)
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold">Days to Sell:</span>
                      <span>{property.daysListed}</span>
                    </div>
                  </div>
                </div>
              </div>

              {soldProperties && soldProperties.length > 0 && (
                <div class="w-full space-y-8">
                  <h4>SOLD MATCHES</h4>
                  <p>
                    Getting the first part of the process right helps to create
                    the maximum outcome. This is a logical range derived from
                    market insights and the final outcome may vary significantly
                  </p>
                  <Slider {...settings} className="w-full">
                    {soldProperties.map(({ property, score, keyMatches }) => (
                      <div
                        key={property._id}
                        className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-lightgray hover:bg-mediumgray py-4 px-6"
                        onClick={() => handlePropertyClick(property)}
                      >
                        <img
                          className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] grayscale"
                          src={
                            property?.media[0]?.url || "/placeholder-image.jpg"
                          }
                          alt="property"
                        />
                        <div className="text-center mt-4 space-y-3">
                          <p className="font-semibold">
                            {formatCurrency(property.price)} ({score}%)
                          </p>
                          <p className="text-sm">{property.address}</p>
                          <div className="flex items-center justify-center gap-4">
                            <div className="text-sm">
                              <i className="fa fa-bed me-2"></i>
                              {property.bedrooms || "N/A"}
                            </div>
                            <div className="text-sm">
                              <i className="fa fa-bath me-2"></i>
                              {property.bathrooms || "N/A"}
                            </div>
                            <div className="text-sm">
                              <i className="fa fa-car me-2"></i>
                              {property.carspaces || "N/A"}
                            </div>
                            <div className="text-sm">
                              {property.propertyType}
                            </div>
                          </div>
                          <div className="text-xs text-start space-y-1">
                            <p className="font-semibold">Key Matches</p>
                            <p className="italic">{keyMatches.join(", ")}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}

              {saleProperties && saleProperties.length > 0 && (
                <div class="w-full space-y-8">
                  <h4>ON MARKET MATCHES</h4>
                  <Slider {...settings} className="w-full">
                    {saleProperties.map(({ property, score, keyMatches }) => (
                      <div
                        key={property._id}
                        className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-lightgray hover:bg-mediumgray py-4 px-6"
                        onClick={() => handlePropertyClick(property)}
                      >
                        <img
                          className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] grayscale"
                          src={
                            property?.media[0]?.url || "/placeholder-image.jpg"
                          }
                          alt="property"
                        />
                        <div className="text-center mt-4 space-y-3">
                          <p className="font-semibold">
                            {formatCurrency(property.price)} ({score}%)
                          </p>
                          <p className="text-sm">{property.address}</p>
                          <div className="flex items-center justify-center gap-4">
                            <div className="text-sm">
                              <i className="fa fa-bed me-2"></i>
                              {property.bedrooms || "N/A"}
                            </div>
                            <div className="text-sm">
                              <i className="fa fa-bath me-2"></i>
                              {property.bathrooms || "N/A"}
                            </div>
                            <div className="text-sm">
                              <i className="fa fa-car me-2"></i>
                              {property.carspaces || "N/A"}
                            </div>
                            <div className="text-sm">
                              {property.propertyType}
                            </div>
                          </div>
                          <div className="text-xs text-start space-y-1">
                            <p className="font-semibold">Key Matches</p>
                            <p className="italic">{keyMatches.join(", ")}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </div>
          </DisclosurePanel>
        </Disclosure>

        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">STEP 2: LET’S LOOK AT PROCESSES</span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="mt-4">
            <div className="space-y-8">
              {soldProperties && soldProperties.length > 0 && (
                <div className="space-y-4 w-full">
                  <h4>SCORE MATCH ON MARKET</h4>
                  <GoogleChart dataPoints={dataPoints} />
                </div>
              )}

              <div className="space-y-4 w-full">
                <h4>AREA SALES PROCESS BREAKDOWN</h4>
                <GooglePieChart data={pieChartData} />
              </div>

              {recentAreaSoldProcess && recentAreaSoldProcess.length > 0 && (
                <RecentAreaSoldProcess
                  recentAreaSoldProcess={recentAreaSoldProcess}
                />
              )}
            </div>
          </DisclosurePanel>
        </Disclosure>

        {/* <div class="w-full max-w-sm mx-auto">
          <h4 class="mb-8">HOW BUYERS SEARCH</h4>
          <ul className="list-none m-0">
            <li>Search</li>
            <hr class="my-3" />
            <li>Compare</li>
            <hr class="my-3" />
            <li>Inspect</li>
            <hr class="my-3" />
            <li>Connect</li>
            <hr class="my-3" />
            <li>Decide</li>
            <hr class="my-3" />
          </ul>
        </div> */}

        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">STEP 3: LET’S GET THE MAXIMUM</span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="mt-4">
            <div className="space-y-8">
              <ProcessChain property={property} />

              {logicalPrice && (
                <RecommendedSalesProcess
                  recommendedSaleProcess={recommendedSaleProcess}
                  highEndProperties={highEndProperties}
                  lowEndProperties={lowEndProperties}
                  address={property.address}
                  developmentPotential={property.developmentPotential}
                  logicalPrice={logicalPrice}
                  setLogicalPrice={setLogicalPrice}
                  soldProperties={soldProperties}
                  areaDynamics={areaDynamics}
                  propertyType={property.propertyType}
                  duplexProperties={duplexProperties}
                />
              )}

              <div class="flex items-center justify-center">
                <img
                  src={processExampleImg}
                  alt="Property"
                  class="w-full h-auto max-w-[600px]"
                />
              </div>

              <div class="space-y-4 flex flex-col items-center justify-center">
                <h4>PROCESS MAXIMISES THE OUTCOME</h4>
                <img
                  src={propertyImg2}
                  alt="Property"
                  class="w-full h-auto max-w-[600px]"
                />
              </div>

              <div class="flex gap-4 items-center justify-center">
                <div class="flex items-center justify-center w-24 h-24 bg-neutral-100 rounded-full text-sm text-center">
                  Price
                </div>
                <div class="flex items-center justify-center w-24 h-24 bg-neutral-100 rounded-full text-sm text-center">
                  Marketing
                </div>
                <div class="flex items-center justify-center w-24 h-24 bg-neutral-100 rounded-full text-sm text-center">
                  Process
                </div>
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>

        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">STEP 4: THE MARKETING</span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="mt-4">
            <div className="space-y-8">Photos come here</div>
          </DisclosurePanel>
        </Disclosure>

        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">TOOLS</span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="mt-2 space-y-12">
            <div class="w-full space-y-8 flex flex-col items-center justify-center">
              <h4>PROCESS TIMELINE</h4>
              <img
                src={propertyImg5}
                alt="Property"
                class="w-full h-auto max-w-[600px]"
              />
            </div>

            <div class="w-full space-y-8 flex flex-col items-center justify-center">
              <h4>THE GAP</h4>
              <img
                src={propertyImg7}
                alt="Property"
                class="w-full h-auto max-w-[600px]"
              />
            </div>
          </DisclosurePanel>
        </Disclosure>

        {/* <div class="space-y-16">
          <h4>MARKETING</h4>
          <p class="text-xl">
            “We believe people don’t buy products; they buy the feeling the
            product gives them.”
          </p>
        </div> */}

        {/* {property?.marketing && (
          <div className="w-full">
            <h4>SHOPPING CART</h4>
            <div className="w-full flex items-center justify-center">
              <MarketingPrice
                tab="price-and-process"
                onConfirm={handleSaveMarketingData} // Pass function to save selected items
                existingData={property.marketing} // Watch for existing form data
              />
            </div>
          </div>
        )} */}

        {/* <div class="space-y-8 flex flex-col items-center justify-center">
          <h4>PHOTOS</h4>
          <img
            src={propertyImg8}
            alt="Property"
            class="w-full h-auto max-w-[600px]"
          />
          <img
            src={propertyImg9}
            alt="Property"
            class="w-full h-auto max-w-[600px]"
          />
          <img
            src={propertyImg10}
            alt="Property"
            class="w-full h-auto max-w-[600px]"
          />
          <img
            src={propertyImg11}
            alt="Property"
            class="w-full h-auto max-w-[600px]"
          />
          <img
            src={propertyImg12}
            alt="Property"
            class="w-full h-auto max-w-[600px]"
          />
        </div>

        <div className="w-full h-[90vh] relative py-16">
          <video
            autoPlay
            muted
            loop
            preload="metadata"
            playsInline
            webkit-playsinline="true"
            className="w-full h-full object-cover"
          >
            <source
              src="https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/videos/price-process.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div> */}
      </div>
      <RegenerateLogicalPrice
        property={property}
        regenerateLogicalPrice={regenerateLogicalPrice}
        setRegenerateLogicalPrice={setRegenerateLogicalPrice}
        soldProperties={soldProperties}
        setLogicalPrice={setLogicalPrice}
        setLogicalReasoning={setLogicalReasoning}
      />
      <PropertyDetailsModal
        isOpen={isPropertyClicked}
        onClose={() => setIsPropertyClicked(false)}
        property={selectedProperty}
      />
    </div>
  );
};

const RegenerateLogicalPrice = ({
  property,
  regenerateLogicalPrice,
  setRegenerateLogicalPrice,
  soldProperties,
  setLogicalPrice,
  setLogicalReasoning,
}) => {
  const [checkedProperties, setCheckedProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  // Handle checkbox selection
  const handleCheckboxChange = (propertyId) => {
    setCheckedProperties(
      (prevChecked) =>
        prevChecked.includes(propertyId)
          ? prevChecked.filter((id) => id !== propertyId) // Uncheck
          : [...prevChecked, propertyId] // Check
    );
  };

  // Handle regenerate button click
  const handleRegenerate = async () => {
    try {
      setLoading(true);
      const response = await axios.post("/property/regenerateLogicalPrice", {
        property: property,
        checkedProperties: soldProperties.filter(({ property }) =>
          checkedProperties.includes(property._id)
        ),
      });
      if (response.data.success) {
        console.log(response);
        setRegenerateLogicalPrice(false);
        setLogicalPrice(response.data.data.logicalPrice);
        setLogicalReasoning(response.data.data.logicalReasoning);
        await axios.put("/userProperty", {
          address: property.address,
          logicalPrice: response.data.data.logicalPrice,
          logicalReasoning: response.data.data.logicalReasoning,
        });
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={regenerateLogicalPrice}
      onClose={() => setRegenerateLogicalPrice(false)}
      title="Regenerate Logical Price"
      className="max-w-2xl"
    >
      {soldProperties && soldProperties.length > 0 && (
        <div className="w-full overflow-x-auto">
          <table className="w-full border-collapse text-center">
            <thead>
              <tr className="bg-lightgray">
                <th className="py-2 px-3">Select</th>
                <th className="py-2 px-3">Address</th>
                <th className="py-2 px-3">Price</th>
                <th className="py-2 px-3">Score Match</th>
              </tr>
            </thead>
            <tbody>
              {soldProperties.map(({ property, score }, index) => (
                <tr key={property._id} className="border-b">
                  <td className="py-2 px-3">
                    <input
                      type="checkbox"
                      checked={checkedProperties.includes(property._id)}
                      onChange={() => handleCheckboxChange(property._id)}
                    />
                  </td>
                  <td className="py-2 px-3 flex flex-col gap-2">
                    <img
                      src={property?.media?.[0]?.url}
                      alt="property"
                      className="w-auto h-24 sm:h-auto max-h-40"
                    ></img>
                    {property.address}
                  </td>
                  <td className="py-2 px-3">{property.price}</td>
                  <td className="py-2 px-3">{score}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="flex justify-end mt-4">
        <Button
          onClick={handleRegenerate}
          className="black-button"
          loading={loading}
        >
          Regenerate
        </Button>
      </div>
    </Modal>
  );
};

const PriceProcess = ({
  isCleaned,
  property, // Use 'property' directly
  updateBoxLoading,
  updateBoxStatus,
}) => {
  console.log("PriceProcess received property:", property);

  const [formData, setFormData] = useState(property);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // Fields to compare and send in the PUT request
  const fieldsToCompare = [
    "propertyType",
    "bedrooms",
    "bathrooms",
    "carspaces",
    "landArea",
    "buildingArea",
    "buildType",
    "wallMaterial",
    "pool",
    "tennisCourt",
    "waterViews",
    "finishes",
    "streetTraffic",
    "topography",
    "additionalInformation",
    "frontage",
    "configurationPlan",
    "grannyFlat",
    "developmentPotential",
  ];

  // Function to extract only the relevant fields from an object
  const extractFields = (obj) => {
    return fieldsToCompare.reduce((result, field) => {
      if (obj.hasOwnProperty(field)) {
        result[field] = obj[field];
      }
      return result;
    }, {});
  };

  // Function to handle the form submission and pass data to PropertyResult
  const handleFormSubmit = async (data) => {
    try {
      updateBoxLoading(true);

      // Extract the relevant fields from both the property and the new data
      const initialFields = extractFields(property);
      const newFields = extractFields(data);

      // Check if there's any difference between the fields
      if (
        isEqual(initialFields, newFields) &&
        property.boxStatus[1].status === "complete"
      ) {
        console.log(
          "No changes detected in the relevant fields, skipping update."
        );
        updateBoxLoading(false);
        return; // Skip the update if there's no change
      }

      if (newFields.developmentPotential === "") {
        newFields.developmentPotential = null;
      }

      // Proceed with the update if the relevant fields have changed
      const response = await axios.put("/userProperty", {
        address: property.address,
        ...newFields, // Send only the relevant fields in the PUT request

        // Include boxStatusUpdates if needed
        ...(data.boxStatus[1].status !== "complete" && {
          boxStatusUpdates: [
            {
              name: "priceProcess",
              status: "complete",
            },
            {
              name: "postList",
              status: "unlock",
            },
            {
              name: "authoriseSchedule",
              status: "unlock",
            },
          ],
        }),
      });

      if (response.data.success) {
        console.log("Updated property:", response.data.data);
        // Update formData with response data
        setFormData(response.data.data); // Assuming response.data.data contains the updated property data
        setIsSubmitted(true); // This will show the PropertyResult component
        setIsEditing(false);
        // Trigger the status update in the parent component with the latest boxStatus
        updateBoxStatus(response.data.data.boxStatus);
      }
    } catch (error) {
      console.log("Error in handleFormSubmit:", error.message);
    } finally {
      setIsEditing(false);
      // Trigger the loading state as false (done)
      updateBoxLoading(false);
    }
  };

  // Function to go back to editing the form
  const handleEdit = () => {
    setIsSubmitted(false); // Switch back to the form
    setIsEditing(true); // Enable editing mode
  };

  useEffect(() => {
    // Update the form data if the property changes
    setFormData(property);
  }, [property]);

  // if (!isCleaned) {
  //   return (
  //     <div className="flex items-center justify-center mt-8">
  //       <i className="fa-solid fa-spinner animate-spin mr-2"></i>
  //       <span>AI cleaning is in progress...</span>
  //     </div>
  //   );
  // }

  return (
    <div className="w-full">
      {isEditing ? (
        <PropertyForm
          key={property.id}
          property={formData}
          onSubmitForm={handleFormSubmit}
        />
      ) : !isSubmitted && property.boxStatus[1].status !== "complete" ? (
        <PropertyForm property={formData} onSubmitForm={handleFormSubmit} />
      ) : (
        <PropertyResult property={formData} onEdit={handleEdit} />
      )}
    </div>
  );
};

const PropertyDetailsModal = ({ isOpen, onClose, property }) => {
  if (!property) return null;

  const {
    address,
    price,
    suburb,
    postcode,
    propertyType,
    bedrooms,
    bathrooms,
    carspaces,
    landArea,
    buildingArea,
    buildType,
    frontage,
    developmentPotential,
    features,
    media,
    pool,
    tennisCourt,
    finishes,
    description,
    headline,
    saleProcess,
    configurationPlan,
    beleefSaleProcess,
  } = property;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Property Details">
      <div className="space-y-6">
        {/* <h4 className="font-semibold text-lg">{headline}</h4>
        <p className="text-gray-700">{description}</p> */}

        <table className="w-full text-sm">
          <tbody>
            <tr className="bg-white">
              <td className="p-2 ">Address</td>
              <td className="p-2">{address}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Price</td>
              <td className="p-2">${price?.toLocaleString()}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Suburb</td>
              <td className="p-2">{suburb}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Postcode</td>
              <td className="p-2">{postcode}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Property Type</td>
              <td className="p-2">{propertyType}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Bedrooms</td>
              <td className="p-2">{bedrooms}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Bathrooms</td>
              <td className="p-2">{bathrooms}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Carspaces</td>
              <td className="p-2">{carspaces}</td>
            </tr>

            {propertyType === "ApartmentUnitFlat" ? (
              <tr className="bg-lightgray">
                <td className="p-2 ">Building Area</td>
                <td className="p-2">{buildingArea} m²</td>
              </tr>
            ) : (
              <tr className="bg-white">
                <td className="p-2 ">Land Area</td>
                <td className="p-2">{landArea} m²</td>
              </tr>
            )}
            <tr className="bg-white">
              <td className="p-2 ">Frontage</td>
              <td className="p-2">{frontage || `N/A`} m²</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Development Potential</td>
              <td className="p-2">{developmentPotential || null}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Build Type</td>
              <td className="p-2">{buildType}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Pool</td>
              <td className="p-2">{pool}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Tennis Court</td>
              <td className="p-2">{tennisCourt}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Finishes</td>
              <td className="p-2">{finishes}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Sale Process</td>
              <td className="p-2">{saleProcess}</td>
            </tr>
            <tr className="bg-white">
              <td className="p-2 ">Beleef Sale Process</td>
              <td className="p-2">{beleefSaleProcess}</td>
            </tr>
            <tr className="bg-lightgray">
              <td className="p-2 ">Configuration Plan</td>
              <td className="p-2">{configurationPlan}</td>
            </tr>
          </tbody>
        </table>

        {/* Media Slider */}
        <div className="mt-6">
          <h4>Property Images</h4>
          {media?.length > 0 && (
            <Slider {...settings}>
              {media.map((img, idx) => (
                <div key={idx} className="w-full h-[200px]">
                  <img
                    src={img.url || "/placeholder-image.jpg"}
                    alt={`property-${idx}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default PriceProcess;
