import React, { useState, useEffect } from "react";
import axios from "utils/axios";

const Home = () => {
  const [selectedBox, setSelectedBox] = useState("");

  const boxes = [
    {
      title: "Ask Sandy",
      text: "rental appraisals to common questions",
      type: "link",
    },
    {
      title: "Start Campaign",
      text: "everything from appraisals to launch",
      type: "link",
    },
    {
      title: "Quick Search",
      text: "search price and property details",
      type: "link",
    },
  ];

  const componentsMap = {
    "Ask Sandy": "/sandy",
    "Start Campaign": "/chat",
    "Quick Search": "/quick-search",
  };
  console.log();

  const handleBoxClick = (box) => {
    setSelectedBox(box.title);
    if (box.type === "link") {
      window.location.href = componentsMap[box.title];
    }
  };

  const currentComponent =
    selectedBox &&
    boxes.find((box) => box.title === selectedBox)?.type === "component"
      ? componentsMap[selectedBox]
      : null;

  return (
    <div className="container">
      {!currentComponent && (
        <p className="text-mediumgray text-center h-[50vh] flex items-center justify-center">
          Beleef Model 1
        </p>
      )}

      {currentComponent &&
        React.createElement(currentComponent, { propertyData: {} })}

      <div
        className={`z-10 w-full fixed left-0 right-0 bg-white px-6 flex items-center justify-center bottom-0 pb-12 pt-2`}
      >
        <div className="overflow-x-auto whitespace-nowrap box-scrollbar scroll-smooth">
          {boxes.map((box, index) => (
            <div
              key={index}
              className={`bg-lightgray rounded-xl flex-shrink-0 inline-flex flex-col items-center hover:bg-mediumgray cursor-pointer mr-4 py-2.5 px-6 ${
                box.title === selectedBox ? "bg-mediumgray" : ""
              }`}
              onClick={() => handleBoxClick(box)}
            >
              <div className="text-start text-xs relative">
                <h6>{box.title}</h6>
                <span className=" text-darkergray">{box.text}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
