import React, { useState, useEffect, useRef, useContext } from "react";
import { AuthContext } from "context/AuthContext";
import axios from "utils/axios";
import video from "assets/videos/postlist-video.mp4";
import Button from "components/ui/Button";
import propertyImg1 from "assets/images/property-img-1.jpg";
import propertyImg3 from "assets/images/property-img-3.jpg";
import propertyImg6 from "assets/images/property-img-6.jpg";
import propertyImg13 from "assets/images/property-img-13.jpg";
import propertyImg14 from "assets/images/property-img-14.jpg";
import propertyImg15 from "assets/images/property-img-15.jpg";
import { useNavigate } from "react-router-dom";
import arrow from "assets/images/arrow.png";
import EditableField from "components/ui/EditableField";
import DeleteField from "components/ui/DeleteField";
import { showToast } from "components/ui/toast";
import "./Postlist.css";
import MarketingPrice from "components/ui/MarketingPrice";

const ProcessChain = ({ property }) => {
  const [chainStep, setChainStep] = useState(0);
  const [isProcessChain, setIsProcessChain] = useState(false);
  const [steps, setSteps] = useState(property.processChain);

  const goToChainStep = async (index) => {
    if (index === 0 || steps[index - 1].selected === true) {
      setChainStep(index);
      setIsProcessChain(true);
    }
    if (index === 6) {
      const updatedSteps = [...steps];
      updatedSteps[6].selected = true;
      setSteps(updatedSteps);

      try {
        await axios.put("/userProperty", {
          address: property.address,
          processChain: steps,
        });
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const selectOption = (stepIndex, isSelected) => {
    const updatedSteps = [...steps];
    updatedSteps[stepIndex].selected = isSelected;
    setSteps(updatedSteps);

    if (isSelected) {
      if (stepIndex < steps.length - 1) {
        setChainStep(stepIndex + 1);
      } else {
        setIsProcessChain(false);
      }
    } else {
      resetFollowingSteps(stepIndex);
      setChainStep(stepIndex);
    }
    setIsProcessChain(false);
  };

  const resetFollowingSteps = async (fromIndex) => {
    const updatedSteps = [...steps];
    for (let i = fromIndex + 1; i < updatedSteps.length; i++) {
      updatedSteps[i].selected = null;
    }
    setSteps(updatedSteps);
    await axios.put("/userProperty", {
      address: property.address,
      processChain: [
        { label: "1", name: "Views", selected: null },
        { label: "2", name: "Enquiry", selected: null },
        { label: "3", name: "Inspection", selected: null },
        { label: "4", name: "Offers", selected: null },
        { label: "5", name: "Close Feedback", selected: null },
        { label: "6", name: "Vendor Acceptance", selected: null },
        { label: "7", name: "Sold", selected: null },
      ],
    });
  };

  return (
    <div className="w-full space-y-16">
      <h4>THE PROCESS CHAIN</h4>
      <div className="w-full flex overflow-x-auto box-scrollbar">
        {steps.map((step, index) => (
          <div key={index} className="flex items-center">
            <div>
              <div
                className="flex items-center justify-center border-4 chain border-gray-300 bg-white text-gray-500 cursor-pointer"
                onClick={() => goToChainStep(index)}
              >
                {step.selected === true && "✔️"}
                {step.selected === false && "❌"}
                {step.selected === null && ""} {/* Initial state */}
              </div>
              <p className="mt-2 mb-0 min-h-[32px] max-w-[100px] text-xs">
                {step.name}
              </p>
            </div>
            {index < steps.length - 1 && (
              <div className="chain-stick bg-gray-300"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const PostList = ({ property, updateBoxLoading, updateBoxStatus }) => {
  const handleSave = async (fieldPath, newValue) => {
    try {
      console.log("got clicked");
      const postData = {
        fieldPath, // The path of the field being updated, e.g., "soldProperties[0].property.address" or "logicalPrice"
        newValue, // The new value that the user wants to save
      };

      // Making a PUT request to update the post list dynamically
      await axios.put(`/postList/${postList._id}`, postData);
    } catch (error) {
      console.error("Error updating post list:", error);
    }
  };

  const handleDelete = async (fieldPath) => {
    try {
      const postData = {
        fieldPath, // This will be something like `soldProperties[0]`
        remove: true, // Mark that this field is to be removed
      };

      // Making a PUT request to update the post list dynamically
      await axios.put(`/postList/${postList._id}`, postData);
    } catch (error) {
      console.error("Error deleting post list item:", error);
    }
  };

  const MaximumOutcomeProcess = ({
    logicalPrice,
    soldProperties,
    recommendedSaleProcess,
  }) => {
    const formatCurrency = (value) => {
      if (value === undefined || value === null) return "N/A";
      return "$" + new Intl.NumberFormat().format(value);
    };

    return (
      <div className="py-8 space-y-20">
        <div className="space-y-16">
          <h4>MAXIMUM OUTCOME PROCESS</h4>
          <p>{recommendedSaleProcess || "N/A"}</p>
          <p>
            Everyone deserves the maximum outcome. It's not just the method of
            sale, but also the intricate details within the process that will
            maximise the result. We have carefully chosen this method.
          </p>
        </div>

        {/* Process Name */}
        {/* <h4 className="text-lg font-semibold mb-6">{getProcessName(data.process)}</h4> */}

        {/* Step 1 */}
        <div className="space-y-16">
          <div className="flex items-center gap-8 bg-lightgray rounded-lg py-2 px-4">
            <p>Step 1</p>
            <p>Price Point</p>
          </div>

          {/* LOGICAL ASSESSMENT */}
          <div className="space-y-16">
            <h4>LOGICAL ASSESSMENT</h4>
            <p className="font-bold">
              <EditableField
                value={logicalPrice}
                onSave={(newValue) => {
                  handleSave("logicalPrice", newValue); // Call the save function
                  setPostList({ ...postList, logicalPrice: newValue }); // Update postList in state
                }}
              />
            </p>
          </div>

          <div className=" flex flex-col items-center justify-center space-y-16">
            <div className="max-w-sm mx-auto space-y-8 flex flex-col items-center justify-center">
              <div className="flex flex-row justify-between w-full text-sm">
                <span>Buyers start logically</span>
                <span>and finish emotionally</span>
              </div>
              <img src={arrow} alt="arrow" />
            </div>

            <p className="text-center">
              Getting the first part of the process right helps to create the
              maximum outcome. This is a logical range derived from market
              insights and the final outcome may vary significantly.
            </p>

            {soldProperties && soldProperties.length > 0 && (
              <div className="w-full overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-lightgray ">
                      <th className="py-2 px-3">Address</th>
                      <th className="py-2 px-3">Price</th>
                      <th className="py-2 px-3">Score Match</th>
                      <th className="py-2 px-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {soldProperties.map(({ property, score }, index) => (
                      <tr key={property._id} className="border-b">
                        <td className="py-2 px-3">
                          {property.address}
                          {/* <EditableField
                            value={property.address}
                            onSave={(newValue) => {
                              handleSave(
                                `soldProperties[${index}].property.address`,
                                newValue
                              ); // API call to save the new address
                              const updatedSoldProperties = [...soldProperties];
                              updatedSoldProperties[index].property.address =
                                newValue;
                              setPostList({
                                ...postList,
                                soldProperties: updatedSoldProperties,
                              });
                            }}
                          /> */}
                        </td>
                        <td className="py-2 px-3">
                          {formatCurrency(property.price)}
                          {/* <EditableField
                            value={formatCurrency(property.price)}
                            onSave={(newValue) => {
                              handleSave(
                                `soldProperties[${index}].property.price`,
                                newValue
                              ); // API call to save the new price
                              const updatedSoldProperties = [...soldProperties];
                              updatedSoldProperties[index].property.price =
                                newValue;
                              setPostList({
                                ...postList,
                                soldProperties: updatedSoldProperties,
                              });
                            }}
                            isCurrency={true}
                          /> */}
                        </td>
                        <td className="py-2 px-3">{score}%</td>
                        <td className="py-2 px-3">
                          <DeleteField
                            onDelete={() => {
                              // Remove the selected property
                              const updatedSoldProperties =
                                soldProperties.filter((_, i) => i !== index);
                              handleDelete(`soldProperties[${index}]`); // Call API to delete the row
                              setPostList({
                                ...postList,
                                soldProperties: updatedSoldProperties,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        {/* Step 2 */}
        <div className="space-y-16">
          <div className="flex items-center gap-8 bg-lightgray rounded-lg py-2 px-4">
            <p>Step 2</p>
            <p>Engagement</p>
          </div>
          <p>
            Assess: Enquiry Inspections Engagement Determine if the price is
            adjusted, remains, or is raised.
          </p>
        </div>

        {/* Step 3 */}
        <div className="space-y-16">
          <div className="flex items-center gap-8 bg-lightgray rounded-lg py-2 px-4">
            <p>Step 3</p>
            <p>Price Assessment</p>
          </div>
          {soldProperties && soldProperties.length > 0 && (
            <div className="w-full overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-lightgray ">
                    <th className="py-2 px-3">Address</th>
                    <th className="py-2 px-3">Price</th>
                    <th className="py-2 px-3">Score Match</th>
                    <th className="py-2 px-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {soldProperties.map(({ property, score }, index) => (
                    <tr key={property._id} className="border-b">
                      <td className="py-2 px-3">{property.address}</td>
                      <td className="py-2 px-3">
                        {formatCurrency(property.price)}
                      </td>
                      <td className="py-2 px-3">{score}%</td>
                      <td className="py-2 px-3">
                        <DeleteField
                          onDelete={() => {
                            // Remove the selected property
                            const updatedSoldProperties = soldProperties.filter(
                              (_, i) => i !== index
                            );
                            handleDelete(`soldProperties[${index}]`); // Call API to delete the row
                            setPostList({
                              ...postList,
                              soldProperties: updatedSoldProperties,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Step 4 */}
        <div className="space-y-16">
          <div className="flex items-center gap-8 bg-lightgray rounded-lg py-2 px-4">
            <p>Step 4</p>
            <p>Closing Date Live</p>
          </div>
          <p>
            Determine if the date will be moved forward or the date is moved
            back based on the engagement.
          </p>
        </div>

        {/* Step 5 */}
        <div className="space-y-16">
          <div className="flex items-center gap-8 bg-lightgray rounded-lg py-2 px-4">
            <p>Step 5</p>
            <p>Finalise</p>
          </div>
          <p>Maximum Outcome</p>
        </div>
      </div>
    );
  };

  const [loading, setLoading] = useState(false);
  const [postListResult, setPostListResult] = useState(false);

  const [logicalPrice, setLogicalPrice] = useState(null);
  const [saleProperties, setSaleProperties] = useState([]);
  const [soldProperties, setSoldProperties] = useState([]);
  const [areaDynamics, setAreaDynamics] = useState(null);
  const [engagedPurchaser, setEngagedPurchaser] = useState(null);

  const [postList, setPostList] = useState(null);

  const { user } = useContext(AuthContext);
  const { name, email, picture } = user || {};

  useEffect(() => {
    // Only proceed if property.address is defined
    if (!property?.address) return;

    const fetchPostList = async () => {
      try {
        const response = await axios.get(`/postlist/${property.address}`);

        // Check if response contains the data array and has some length
        if (response?.data?.success) {
          setPostList(response.data.data); // Set postList with data
          setPostListResult(true); // Set result flag to true
        }
      } catch (error) {
        console.error("Error fetching post list:", error);
        setPostListResult(false); // Optional: set to false on error
      } finally {
        setLoading(false);
      }
    };

    fetchPostList();

    // Add property.address as a dependency, so the effect only runs when address changes
  }, [property.address]);

  useEffect(() => {
    if (!postListResult && !property?._id) return;

    const fetchAreaDynamics = async () => {
      try {
        const response = await axios.get(`/property/suburb/${property.suburb}`);

        setAreaDynamics(response?.data?.data);
      } catch (error) {
        console.error("Error fetching area dynamics:", error);
      }
    };

    const fetchRecommendProperties = async () => {
      try {
        if (property.engagedPurchaser !== null) {
          setLogicalPrice(property.logicalPrice || null);
          setSaleProperties(property.recommendedSales || []);
          setSoldProperties(property.recommendedSold || []);
          setEngagedPurchaser(property.engagedPurchaser || null);
          return;
        }

        const response = await axios.post("/property/recommend", {
          property, // Send the whole property object in the request body
        });

        setLogicalPrice(response?.data?.data?.logical?.logicalPrice || null);

        setSaleProperties(response?.data?.data?.recommendedSales || []);
        setSoldProperties(response?.data?.data?.recommendedSold || []);
        setEngagedPurchaser(response?.data?.data?.engagedPurchaser || null);
      } catch (error) {
        console.error("Error fetching recommended properties:", error);
      }
    };

    const executeFetchFunctions = async () => {
      try {
        await fetchAreaDynamics(); // Waits for this to complete before moving on
        await fetchRecommendProperties(); // Waits for this to complete
        // await fetchBeleefSaleProcess(); // Waits for this to complete
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    executeFetchFunctions();

    // Only trigger the useEffect when property._id changes
  }, [postListResult, property._id]);

  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [isImageUpload, setIsImageUpload] = useState(false);
  const [images, setImages] = useState([]);
  const fileInputRef = useRef(null); // Reference for the file input

  const [isTypingEffect, setIsTypingEffect] = useState(false);
  const messagesContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [questions, setQuestions] = useState([
    {
      id: 1,
      question: "Can you describe the owner's current situation?",
      answer: "",
      prompt: "POSTLIST_PROMPT_SITUATION",
      chatgptAnswer: "",
      isImage: false,
    },
    {
      id: 2,
      question:
        "What are the key features of the property? Upload up to 3 images or write the description",
      answer: "",
      prompt: "POSTLIST_PROMPT_IMAGE_UPLOAD",
      chatgptAnswer: "",
      isImage: true,
    },
    {
      id: 3,
      question: "What is the commission fee you will charge?",
      isImage: false,
      answer: "",
      prompt: "POSTLIST_PROMPT_FEE",
      chatgptAnswer: "",
    },
    {
      id: 4,
      question: "What does the owner need to do to maximise the outcome?",
      isImage: false,
      answer: "",
      prompt: "POSTLIST_PROMPT_IMPROV_MAX_OUTCOME",
      chatgptAnswer: "",
    },
  ]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const askNextQuestion = async () => {
    if (currentQuestionIndex < questions.length) {
      const currentQuestion = questions[currentQuestionIndex];
      setIsImageUpload(currentQuestion.isImage);

      // Call typewriterEffect directly, using messages.length to calculate message index
      typewriterEffect(
        currentQuestion.question,
        messages.length // Set correct index for the question
      );
    } else {
      // When all questions are done, we handle the final thank you response
      typewriterEffect("Thank you for your response", messages.length);

      try {
        setButtonLoading(true);
        const apiCallSuccess = await chatgptAPICall();
        if (apiCallSuccess) {
          const response = await axios.post("/postList", {
            address: property.address,
            suburb: property.suburb,
            ownerSituation: apiCallSuccess[0].chatgptAnswer,
            keyFeatures: apiCallSuccess[1].chatgptAnswer,
            commissionFee: apiCallSuccess[2].chatgptAnswer,
            improvementMaxOutcome: apiCallSuccess[3].chatgptAnswer,
            logicalPrice: logicalPrice,
            soldProperties: soldProperties,
            engagedPurchaser: engagedPurchaser,
            agent: {
              name,
              email,
              picture,
            },
            processChain: property.processChain,
            vendors: property?.vendorDetails || [],
            recommendedSaleProcess: property?.recommendedSaleProcess,
            propertyId: property._id,
          });

          if (response.data.success) {
            setPostList(response.data.data);
            try {
              await axios.put("/userProperty", {
                address: property.address,
                boxStatusUpdates: [
                  {
                    name: "postList",
                    status: "complete",
                  },
                ],
              });
            } catch (error) {
              console.log(error.message);
            } finally {
              setPostListResult(true);
              updateBoxStatus();
            }
          }
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setButtonLoading(false);
        updateBoxLoading(false);
      }
    }
  };

  // useEffect to trigger askNextQuestion when currentQuestionIndex changes
  useEffect(() => {
    if (currentQuestionIndex <= questions.length) {
      askNextQuestion();
    }
  }, [currentQuestionIndex]);

  const handleSend = () => {
    if (isTypingEffect) return;
    const userInput = inputValue.trim();
    if (!userInput && !isImageUpload) {
      showToast("error", "Please type something");
      return;
    }
    if (!userInput && images.length === 0 && isImageUpload) {
      showToast("error", "Please type something or upload an image");
      return;
    }

    const userMessage = {
      role: "user",
      content: userInput,
      images: images.map((image) => image.url),
    };
    setMessages((prev) => [...prev, userMessage]);

    setQuestions((prevQuestions) => {
      const updatedQuestions = prevQuestions.map((question, index) =>
        index === currentQuestionIndex
          ? {
              ...question,
              answer:
                images.length > 0
                  ? { text: userInput, images: images }
                  : userInput, // Handle images and text input
            }
          : question
      );
      return updatedQuestions;
    });

    setInputValue("");
    setImages([]);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const maxFileSize = 20 * 1024 * 1024; // 20MB in bytes
    const allowedFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/webp",
      "image/gif",
    ];

    if (images.length + files.length > 3) {
      showToast("error", "You can only upload up to 3 images.");
      return;
    }

    files.forEach((file) => {
      // Check file type
      if (!allowedFileTypes.includes(file.type)) {
        showToast(
          "error",
          "Invalid file type. Only PNG, JPG, JPEG, WEBP, and GIF are allowed."
        );
        return;
      }

      // Check file size
      if (file.size > maxFileSize) {
        showToast("error", "File size exceeds 20MB limit.");
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setImages((prev) => [...prev, { file, url: e.target.result }]);
      };
      reader.readAsDataURL(file);
    });

    // Reset the file input value to allow re-uploading the same file
    event.target.value = "";
  };

  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const typewriterEffect = (text, messageIndex) => {
    setIsTypingEffect(true);
    let charIndex = 0;

    const interval = setInterval(() => {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];

        // If we have valid text and charIndex is 0, only then initialize the message
        if (!updatedMessages[messageIndex] && charIndex === 0) {
          updatedMessages[messageIndex] = { role: "system", content: "" };
        }

        if (charIndex < text.length) {
          updatedMessages[messageIndex].content = text.slice(0, charIndex + 1); // Update content
          charIndex++;
        }

        return updatedMessages;
      });

      if (charIndex === text.length) {
        clearInterval(interval);
        setIsTypingEffect(false);
      }
    }, 30); // Typewriter effect speed
  };

  const resizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset the height to auto to calculate the new height correctly

      // Attempt to get the computed line height, fallback to a default value if not available
      let lineHeight = parseInt(
        window.getComputedStyle(textarea).lineHeight,
        10
      );
      if (isNaN(lineHeight)) {
        lineHeight = 26.8; // Fallback to a default line height (in pixels)
      }

      const maxRows = 5;
      const maxHeight = lineHeight * maxRows;

      // Apply the max height with overflow handling
      if (textarea.scrollHeight > maxHeight) {
        textarea.style.height = `${maxHeight}px`;
        textarea.style.overflowY = "auto"; // Allow scrolling if content exceeds max height
      } else {
        textarea.style.height = `${textarea.scrollHeight}px`;
        textarea.style.overflowY = "hidden"; // Hide overflow when within limits
      }
    }
  };

  const chatgptAPICall = async () => {
    try {
      const updatedQuestions = await Promise.all(
        questions.map(async (question) => {
          if (question.chatgptAnswer) return question;

          const formData = new FormData();
          if (question.isImage && question.answer?.images?.length > 0) {
            question.answer.images.forEach((image, index) => {
              // formData.append(`file${index}`, image.file);
              formData.append("files", image.file);
            });
          }

          formData.append(`systemPrompt`, question.prompt);
          formData.append(
            `userMessage`,
            `Question: ${question.question}\nAnswer: ${question.answer}`
          );

          const url = question.isImage ? `/openai/image` : `/openai/text`;

          const response = await axios.post(url, formData, {
            headers: {
              "Content-Type": question.isImage
                ? "multipart/form-data"
                : "application/json",
            },
          });
          console.log(response);

          if (response.data.success) {
            return {
              ...question,
              chatgptAnswer: response.data.data,
            };
          } else {
            throw new Error("API response failed");
          }
        })
      );

      setQuestions(updatedQuestions);
      console.log(updatedQuestions);
      return updatedQuestions;
    } catch (error) {
      console.error("Error in chatgptAPICall:", error);
      return false;
    }
  };

  useEffect(() => {
    if (currentQuestionIndex === 0) {
      askNextQuestion();
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleShareClick = async (shareableLink) => {
    if (
      postList.vendors[0].firstName === "First Name" ||
      postList.vendors[0].lastName === "Last Name"
    ) {
      showToast("error", "Vendor first and last name is required");
      return;
    }

    try {
      // Trigger the share dialog with the title and link
      if (navigator.share) {
        // Ensure the share call is synchronous within the user action
        navigator.share({
          title: `Postlist`,
          text: `Hi ${postList.vendors[0].firstName} ${postList.vendors[0].lastName},

Please find enclosed Information for the property at ${property.address}`,
          url: shareableLink,
        });
      }
    } catch (error) {
      console.error("Error generating shareable link:", error);
    }
  };

  const [isSticky, setIsSticky] = useState(false);
  const buttonRef = useRef(null);

  const handleScroll = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      // If the top of the button is less than or equal to 20px from the top, make it sticky
      if (rect.top <= 20 && !isSticky) {
        setIsSticky(true);
      } else if (rect.top > 20 && isSticky) {
        setIsSticky(false);
      }
    }
  };

  const handleSaveMarketingData = async (selectedItems) => {
    await axios.put("/userProperty", {
      address: property.address,
      marketing: selectedItems,
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]);

  if (postListResult) {
    return (
      <div className="postlist flex flex-col items-center justify-center">
        <div className="w-full h-[90vh] relative pb-16">
          <video
            autoPlay
            muted
            loop
            preload="metadata"
            playsInline
            webkit-playsinline="true"
            className="w-full h-full object-cover"
          >
            <source
              src="https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/videos/postlist-video.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="w-full max-w-4xl mx-auto flex flex-col items-center justify-center text-center space-y-20">
          <div className="fixed top-20 right-6 w-full flex justify-end">
            <Button
              className="black-button"
              disabled={loading}
              onClick={() =>
                handleShareClick(`/postlist/${postList.shareableLink}`)
              }
            >
              Share
            </Button>
          </div>

          <h2>Thank you</h2>

          <p>{postList.address}</p>

          <div className="flex items-center flex-col">
            {postList.vendors.map((vendor, index) => (
              <div key={vendor._id} className="flex items-center gap-2">
                {index === 0 && <span>Dear</span>}

                <EditableField
                  value={vendor.firstName || "First Name"} // Display existing first name or default placeholder
                  onSave={(newValue) => {
                    // Pass the correct field path for firstName
                    handleSave(`vendors[${index}].firstName`, newValue);
                    // Update the specific vendor's first name locally in the state
                    const updatedVendors = [...postList.vendors];
                    updatedVendors[index].firstName = newValue;
                    setPostList({ ...postList, vendors: updatedVendors });
                  }}
                />
                <EditableField
                  value={vendor.lastName || "Last Name"} // Display existing last name or default placeholder
                  onSave={(newValue) => {
                    // Pass the correct field path for lastName
                    handleSave(`vendors[${index}].lastName`, newValue);
                    // Update the specific vendor's last name locally in the state
                    const updatedVendors = [...postList.vendors];
                    updatedVendors[index].lastName = newValue;
                    setPostList({ ...postList, vendors: updatedVendors });
                  }}
                />
              </div>
            ))}
          </div>

          <p className="w-full">
            {" "}
            <EditableField
              value={postList.ownerSituation}
              onSave={(newValue) => {
                handleSave("ownerSituation", newValue); // Call the save function
                setPostList({ ...postList, ownerSituation: newValue }); // Update postList in state
              }}
            />
          </p>

          <div class="w-full flex items-center justify-center">
            <img
              src={propertyImg3}
              alt="Property"
              class="w-full h-auto max-w-[600px]"
            />
          </div>

          <p class="text-xl">
            “It’s not just a sale it means more. The maximum outcome is life
            changing”
          </p>

          <div className="flex flex-col space-y-16">
            <h4>Key features of your property to maximise the outcome</h4>

            <EditableField
              value={postList.keyFeatures.heading}
              onSave={(newValue) => {
                handleSave("keyFeatures.heading", newValue); // Call the save function

                // Use computed property names to dynamically update the state
                setPostList((prevPostList) => ({
                  ...prevPostList,
                  keyFeatures: {
                    ...prevPostList.keyFeatures,
                    heading: newValue, // Update the heading field inside keyFeatures
                  },
                }));
              }}
            />

            <EditableField
              value={postList.keyFeatures.description}
              onSave={(newValue) => {
                handleSave("keyFeatures.description", newValue); // Call the save function

                // Use computed property names to dynamically update the state
                setPostList((prevPostList) => ({
                  ...prevPostList,
                  keyFeatures: {
                    ...prevPostList.keyFeatures,
                    description: newValue, // Update the heading field inside keyFeatures
                  },
                }));
              }}
            />
          </div>

          <div class="flex items-center justify-center">
            <img
              src={propertyImg1}
              alt="Property"
              class="w-full h-auto max-w-[600px]"
            />
          </div>

          <div className="flex flex-col space-y-16">
            <h4>Improvements To Maximise The Outcome</h4>

            <div className="text-start">
              <EditableField
                value={postList.improvementMaxOutcome}
                isHtml={true}
                onSave={(newValue) => {
                  handleSave("improvementMaxOutcome", newValue); // Call the save function
                  setPostList({ ...postList, improvementMaxOutcome: newValue }); // Update postList in state
                }}
              />
            </div>
          </div>

          <MaximumOutcomeProcess
            logicalPrice={postList.logicalPrice}
            soldProperties={postList.soldProperties}
            recommendedSaleProcess={postList.recommendedSaleProcess}
          />

          <div class="flex items-center justify-center">
            <img
              src={propertyImg13}
              alt="Property"
              class="w-full h-auto max-w-[600px]"
            />
          </div>

          <div class="w-full">
            <ProcessChain property={property} />
          </div>

          <p>Every step is carefully considered</p>

          <div class="w-full flex items-center justify-center">
            <img
              src={propertyImg15}
              alt="Property"
              class="w-full h-auto max-w-[600px]"
            />
          </div>

          <div class="w-full max-w-lg mx-auto space-y-16">
            <h4>YOUR AREA DYNAMICS</h4>
            {areaDynamics ? (
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-start border-collapse">
                  <thead>
                    <tr className="bg-mediumgray">
                      <th className="py-2 px-3"></th>
                      <th className="py-2 px-3 text-left">
                        <i className="fa-solid fa-house mr-2"></i> House
                      </th>
                      <th className="py-2 px-3 text-left">
                        <i className="fa-solid fa-building mr-2"></i> Unit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b">
                      <td className="py-2 px-3">Median Sale Price</td>
                      <td className="py-2 px-3">
                        {areaDynamics.houseStats
                          ? `$${areaDynamics.houseStats.medianSalePrice?.toLocaleString()}`
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3">
                        {areaDynamics.unitStats
                          ? `$${areaDynamics.unitStats.medianSalePrice?.toLocaleString()}`
                          : "N/A"}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-3">Annual Sales Volume</td>
                      <td className="py-2 px-3">
                        {areaDynamics.houseStats
                          ? areaDynamics.houseStats.annualSalesVolume
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3">
                        {areaDynamics.unitStats
                          ? areaDynamics.unitStats.annualSalesVolume
                          : "N/A"}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-3">Average Days on Market</td>
                      <td className="py-2 px-3">
                        {areaDynamics.houseStats
                          ? areaDynamics.houseStats.averageDaysOnMarket
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3">
                        {areaDynamics.unitStats
                          ? areaDynamics.unitStats.averageDaysOnMarket
                          : "N/A"}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-3">Suburb Growth</td>
                      <td className="py-2 px-3">
                        {areaDynamics.houseStats
                          ? areaDynamics.houseStats.suburbGrowth
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3">
                        {areaDynamics.unitStats
                          ? areaDynamics.unitStats.suburbGrowth
                          : "N/A"}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-3">High Demand Area</td>
                      <td className="py-2 px-3">
                        {areaDynamics.houseStats
                          ? areaDynamics.houseStats.highDemandArea
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3">
                        {areaDynamics.unitStats
                          ? areaDynamics.unitStats.highDemandArea
                          : "N/A"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="flex justify-center items-center">
                <i className="fa-solid fa-spinner animate-spin mr-2"></i>{" "}
                Loading ...
              </div>
            )}
          </div>

          <div class="w-full flex items-center justify-center">
            <img
              src={propertyImg6}
              alt="Property"
              class="w-full h-auto max-w-[600px]"
            />
          </div>

          <div className="flex flex-col space-y-16 engaged-purchaser">
            <h4>MOST LIKELY ENGAGED PURCHASER</h4>
            <EditableField
              value={postList.engagedPurchaser}
              isHtml={true} // Set this to true if your content is HTML
              onSave={(newValue) => {
                handleSave("engagedPurchaser", newValue); // Call the save function
                setPostList({ ...postList, engagedPurchaser: newValue }); // Update postList in state
              }}
            />
          </div>

          <div class="flex items-center justify-center">
            <img
              src={propertyImg14}
              alt="Property"
              class="w-full h-auto max-w-[600px]"
            />
          </div>

          <div class="space-y-16">
            <h4>MARKETING</h4>
            <p class="text-xl">
              “We believe people don’t buy products; they buy the feeling the
              product gives them.”
            </p>
          </div>

          {(postList?.marketing || property?.marketing) && (
            <div className="w-full">
              <h4>SHOPPING CART</h4>
              <div className="w-full flex items-center justify-center">
                <MarketingPrice
                  tab="price-and-process"
                  onConfirm={handleSaveMarketingData} // Pass function to save selected items
                  existingData={postList?.marketing || property?.marketing} // Watch for existing form data
                />
              </div>
            </div>
          )}

          <div className="w-full space-y-16">
            <h4>INVESTMENT</h4>
            <div class="overflow-x-auto">
              <table class="min-w-full border">
                <tbody>
                  <tr>
                    <td class="border text-sm px-4 py-2">
                      <div class="flex justify-center items-center">
                        <EditableField
                          value={postList.commissionFee}
                          onSave={(newValue) => {
                            handleSave("commissionFee", newValue); // Call the save function
                            setPostList({
                              ...postList,
                              commissionFee: newValue,
                            }); // Update postList in state
                          }}
                        />
                        &nbsp;
                        <span>off the purchase price</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="w-full space-y-16">
            <h4>Next Steps</h4>
            <div class="overflow-x-auto">
              <table class="min-w-full border-collapse">
                <thead>
                  <tr class="bg-gray-200 text-gray-700">
                    <th class="py-2 px-3">Week</th>
                    <th class="py-2 px-3">Event</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    "Instruct solicitor to prepare contract of sale, Marketing Preparation, Coming Soon Letters & pointer signs",
                    "Notify off market buyers, launch to market meeting",
                    "Launch to market, Just listed notifications, signboard, brochures, first open home",
                    "Weekly report, vendor meeting to assess engagement, open home",
                  ].map((option, index) => (
                    <tr key={index} className="border-b">
                      <td className="py-2 px-3">{index + 1}</td>
                      <td className="py-2 px-3">{option}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div class="overflow-x-auto">
              <a href="https://23mil.my.canva.site/ausrealty-look-book">
                <table class="min-w-full border ">
                  <tbody>
                    <tr>
                      <td class="border px-4 py-2 font-bold">
                        See more of our work: Click Here
                      </td>
                    </tr>
                  </tbody>
                </table>
              </a>
            </div>

            <div class="flex flex-col space-y-8">
              <img
                src={postList.agent.picture}
                alt="Agent"
                className="w-full max-w-[400px] mx-auto max-h-[600px]"
              />
              <p>{postList.agent.name}</p>
              <p>{postList.agent.email}</p>
            </div>

            {property?.followers.length > 0 &&
              property?.followers.map((follower) => (
                <div className="space-y-8">
                  <img
                    src={follower?.picture}
                    alt="Agent"
                    className="w-full max-w-[400px] mx-auto max-h-[600px]"
                  ></img>
                  <p>{follower?.name}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <div className="w-full max-w-4xl mx-auto flex flex-col flex-grow">
        <div className="m-0 w-full rounded-lg">
          <div
            ref={messagesContainerRef}
            className="enhanced-textarea overflow-y-auto p-3 pl-0 pb-12"
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`mb-4 ${
                  message.role === "system" ? "text-left" : "text-right"
                }`}
              >
                <span
                  className={`inline-block p-2 max-w-[80%] rounded-lg ${
                    message.role === "system"
                      ? "bg-white rounded-br-none"
                      : "text-start bg-gray-200 rounded-bl-none"
                  }`}
                >
                  {message.images && message.images.length > 0 && (
                    <div className="flex gap-4 overflow-x-auto mb-2">
                      {message.images.map((image, imgIndex) => (
                        <div key={imgIndex}>
                          <img
                            src={image}
                            alt=""
                            className="min-w-[200px] w-[200px] min-h-[200px] h-[200px] object-cover"
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  <pre>{message.content}</pre>
                </span>
              </div>
            ))}
          </div>
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div
        className="w-full fixed bottom-0 left-0 right-0 px-4 pb-8 bg-white"
        style={{ zIndex: 1002 }}
      >
        <div className="w-full max-w-4xl mx-auto flex gap-2 items-end justify-center relative">
          <div className="flex flex-col items-center justify-center w-full">
            <div className="flex gap-4 mb-2">
              {images.map((image, index) => (
                <div key={index} className="relative">
                  <img
                    src={image.url}
                    alt="Uploaded"
                    className="w-12 h-12 object-cover rounded-md border border-gray-100"
                  />
                  <button
                    onClick={() => removeImage(index)}
                    className="absolute -top-2 -right-2 bg-gray-100 rounded-full w-5 h-5 p-2 flex items-center justify-center border border-gray-200 text-black"
                  >
                    <i className="fa-solid fa-xmark text-sm"></i>
                  </button>
                </div>
              ))}
            </div>
            <div className="flex gap-2 items-end justify-center w-full">
              {isImageUpload && (
                <div>
                  <label htmlFor="image-upload">
                    <i className="fa-solid fa-paperclip text-xl text-gray-500 cursor-pointer hover:text-black mb-1"></i>
                  </label>
                  <input
                    id="image-upload"
                    ref={fileInputRef}
                    type="file"
                    accept=".png,.jpg,.jpeg,.webp,.gif"
                    className="hidden"
                    onChange={handleImageUpload}
                    multiple
                  />
                </div>
              )}

              <textarea
                ref={textareaRef}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSend();
                  }
                }}
                placeholder="Type here..."
                className="flex-grow p-2 bg-white border border-mediumgray rounded outline-none focus:outline-none resize-none overflow-y-hidden min-h-[34px]"
                rows="1"
                onInput={resizeTextarea}
              />

              {/* <button
                onClick={handleSend}
                className="bg-black text-white rounded-md px-4 py-2 h-[34px]"
                disabled={isTypingEffect}
              >
                <i className="fa-solid fa-location-arrow"></i>
              </button> */}

              <Button
                onClick={handleSend}
                className="bg-black text-white rounded-md px-4 py-2 h-[34px]"
                loading={buttonLoading}
                disabled={isTypingEffect || buttonLoading}
              >
                <i className="fa-solid fa-location-arrow"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostList;
