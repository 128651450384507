import React, { useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
const PdfViewer = () => {
  const [pdfUrl, setPdfUrl] = useState("https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/files/FTR32_Agency_agreement.pdf"); // Replace with your PDF URL

  return (
    <div style={{ height: "750px" }} className="w-full">
      <Worker
        workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}
      >
        <Viewer fileUrl={pdfUrl} />
      </Worker>
    </div>
  );
};

export default PdfViewer;
