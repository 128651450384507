import React, { useState, useRef, useEffect } from "react";
import Modal from "components/ui/Modal";
import Button from "components/ui/Button";
import axios from "utils/axios";
import { useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import {
  fetchSignatureUrl,
  fetchVendorsSignatureUrl,
  formatDateToAEDT,
} from "utils/helperFunctions";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import "./AuthoriseSchedule.css";
import PageLoader from "components/ui/PageLoader";
import { showToast } from "components/ui/toast";
import AuthoriseScheduleEditorLock from "./AuthoriseScheduleEditorLock";
import Tooltip from "components/ui/Tooltip";
import MarketingPrice from "components/ui/MarketingPrice";

const AuthoriseScheduleLock = ({ data }) => {
  const propertyId = data.propertyId;
  useEffect(() => {
    const fetchSignatures = async () => {
      try {
        const signatures = await fetchVendorsSignatureUrl(propertyId);
        // Assuming signatures is an object mapping vendor IDs to signature URLs
        const updatedVendors = authData.vendors.map((vendor, index) => ({
          ...vendor,
          signatureUrl: signatures[index]?.base64Image,
        }));

        setAuthData({ ...authData, vendors: updatedVendors });
      } catch (error) {
        console.error("Error fetching vendor signatures:", error);
      }
    };

    fetchSignatures();
  }, [propertyId]);

  const [loading, setLoading] = useState(false);
  const [sendToSignLoading, setSendToSignLoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const sigCanvasRefs = useRef([]);
  const [signConfirm, setSignConfirm] = useState({});
  const [isSigned, setIsSigned] = useState({});
  const [signatureDataURLs, setSignatureDataURLs] = useState({});
  const [termsOpen, setTermsOpen] = useState({});
  const [agreeTerms, setAgreeTerms] = useState({});
  const [errors, setErrors] = useState({});

  const [authData, setAuthData] = useState(data);
  const [marketingTable, setMarketingTable] = useState(false);

  const [resetConfirm, setResetConfirm] = useState(false);

  const clear = (index) => {
    sigCanvasRefs.current[index]?.clear();
  };

  const handleSaveClick = async (index) => {
    if (!sigCanvasRefs.current[index]?.isEmpty()) {
      const trimmedCanvas = sigCanvasRefs.current[index]
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSignatureDataURLs({ ...signatureDataURLs, [index]: trimmedCanvas });
      setSignConfirm({ ...signConfirm, [index]: true });
    }
  };

  const handleConfirmSign = async (index) => {
    const isTermsValid = agreeTerms[index];
    if (!isTermsValid) {
      setErrors({ ...errors, [index]: { agreeTerms: true } });
      return;
    } else {
      setErrors({ ...errors, [index]: { agreeTerms: false } });
    }

    // Update vendor
    const updatedVendor = {
      signature: signatureDataURLs[index],
      signedDate: formatDateToAEDT(null),
      agreeTerms: true,
      isSigned: true,
    };

    try {
      setLoading(true);
      const res = await axios.put(`/authSchedule/${propertyId}`, {
        vendorId: index,
        vendor: updatedVendor,
      });
      if (res.data.success) {
        setSignConfirm({ ...signConfirm, [index]: false });
        setIsSigned({ ...isSigned, [index]: true });
        window.location.reload();
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const generateAndOpenPDF = async () => {
    try {
      // Open a blank tab immediately in response to user action
      const newWindow = window.open("", "_blank");
  
      if (!newWindow) {
        console.error("Popup was blocked or could not be opened");
        return; // Exit if the window couldn't be opened
      }
  
      newWindow.document.write(`
        <div style="display: flex; justify-content: center; align-items: center; height: 100vh; font-size: 32px; font-family: Arial, sans-serif;">
          <p style="font-size: 32px;">Loading PDF, please wait...</p>
        </div>
      `);
  
      // Make sure to set responseType to 'blob' to handle binary data
      const response = await axios.post(
        "/authSchedule/generatePdf",
        { content: authData }, // This is the request body
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob", // Important for handling binary data
        }
      );
  
      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: "application/pdf" });
  
      // Generate a URL for the Blob
      const pdfUrl = window.URL.createObjectURL(blob);
  
      // Redirect the new tab to the generated PDF
      newWindow.location.href = pdfUrl;
  
      // (Optional) Clean up the URL object after some time to release memory
      setTimeout(() => {
        window.URL.revokeObjectURL(pdfUrl);
      }, 1000 * 60); // 1 minute
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleSendToSign = async (index) => {
    try {
      setSendToSignLoading(true);
      setLoadingIndex(index);
      const payload = {
        propertyAddress: authData.address,
        propertyId: authData.propertyId,
        vendor: authData.vendors[index],
        vendorIndex: index,
      };
      const res = await axios.post(
        "/authSchedule/sendToSign", // Ensure this is the correct endpoint
        { payload }
      );
      if (res.data.success) {
        showToast("success", "Email sent successfully");
        window.location.reload();
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setSendToSignLoading(false);
      setLoadingIndex(null);
    }
  };

  const resetAuthSchedule = async () => {
    const response = await axios.delete(`/authSchedule/${propertyId}`);
    if (response.data.success) {
      window.location.reload();
    }
  };

  if (!authData) {
    return <PageLoader />;
  }

  const {
    address = "",
    solicitor = {},
    commissionFee = "",
    commissionRange = "",
    terms = "",
    status = "",
    startPrice = "",
    endPrice = "",
    saleProcess = "",
    prepareMarketing = "",
    vendors,
    isCompleted,
    access,
    services,
    fraudPrevention,
    marketing
  } = authData;

  const {
    name: solicitorName = "",
    email: solicitorEmail = "",
    address: solicitorAddress = "",
    mobile: solicitorMobile = "",
  } = solicitor;

  return (
    <>
      <div className="w-full px-4">
        <div className="flex items-center justify-between w-full">
          <div className="flex-1 text-center">
            <h5 className="inline-block">
              Status: {isCompleted && "Completed"}
            </h5>
          </div>
          <Button
            className="black-button px-2 py-1 text-xs"
            onClick={() => setResetConfirm(true)}
          >
            Reset
          </Button>
        </div>

        <table className="w-full border border-lightgray border-collapse mt-2">
          <thead>
            <tr className="bg-lightgray">
              <th className="py-2 px-4 text-center">Signer</th>
              <th className="py-2 px-4 text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            {vendors.map((vendor, index) => (
              <Disclosure key={index}>
                {({ open }) => (
                  <>
                    <Disclosure.Button as="tr" className="cursor-pointer">
                      <td className="py-2 px-4 border-b text-center">
                        {vendor.firstName} {vendor.lastName}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {vendor.isSigned
                          ? "Signed"
                          : vendor.viewedDate
                          ? "Viewed"
                          : vendor.sentDate
                          ? "Sent"
                          : ""}
                      </td>
                    </Disclosure.Button>
                    <Disclosure.Panel as="tr">
                      <td colSpan="2" className="border-t">
                        <div className="w-full overflow-x-auto">
                          <table className="w-full table-fixed border-collapse">
                            <thead>
                              <tr className="bg-lightgray">
                                <th className="py-2 px-4 text-center">
                                  Sent Date
                                </th>
                                <th className="py-2 px-4 text-center">
                                  Viewed Date
                                </th>
                                <th className="py-2 px-4 text-center">
                                  Signed Date
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="py-2 px-4 text-center">
                                  {vendor.sentDate || "N/A"}
                                </td>
                                <td className="py-2 px-4 text-center">
                                  {vendor.viewedDate || "N/A"}
                                </td>
                                <td className="py-2 px-4 text-center">
                                  {vendor.signedDate || "N/A"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </tbody>
        </table>
      </div>

      <div className="w-full h-full max-w-4xl mx-auto flex flex-col justify-between authorise-form p-4">
        <p className="mb-4 text-darkgray text-center">{address}</p>
        <div className="flex flex-col gap-4">
          <div className="w-[50%]">
            <input
              type="text"
              value={saleProcess}
              className={`form-input border border-mediumgray`}
              placeholder="SALE PROCESS"
              readOnly
            />
          </div>

          {/* Vendor Information */}
          <div className="mb-4">
            <div
              id="vendor-info"
              className="col-span-12 grid grid-cols-12 gap-4"
            >
              {vendors.map((vendor, index) => (
                <React.Fragment key={index}>
                  <div className="col-span-12 flex justify-between">
                    <div className="flex items-center gap-4">
                      <label className="form-label m-0">
                        Vendor {index + 1}
                      </label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={vendor.isCompany}
                        className="p-0 cursor-pointer"
                        readOnly
                      />
                      Company
                    </div>
                  </div>

                  {/* Licence Upload Section */}
                  <div className="col-span-12 flex justify-between items-center">
                    <div className="flex items-center gap-4">
                      <label>Upload Driver Licence</label>
                    </div>

                    {/* Conditionally Render Scanning or Validation Messages */}
                    <div className="flex items-center gap-2">
                      <div className="col-span-12 flex items-center justify-between gap-2">
                        <span>Licence Uploaded!</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-6">
                    <input
                      type="text"
                      className="form-input border border-mediumgray"
                      value={vendor.firstName}
                      readOnly
                    />
                  </div>

                  <div className="col-span-6">
                    <input
                      type="text"
                      className="form-input border border-mediumgray"
                      value={vendor.lastName}
                      readOnly
                    />
                  </div>

                  <div className="col-span-12">
                    <input
                      type="email"
                      className="form-input border border-mediumgray"
                      value={vendor.email}
                      readOnly
                    />
                  </div>

                  <div className="col-span-12">
                    <input
                      type="text"
                      className="form-input border border-mediumgray"
                      value={vendor.mobile}
                      readOnly
                    />
                  </div>

                  <div className="col-span-12">
                    <input
                      type="text"
                      className="form-input border border-mediumgray"
                      value={vendor.address}
                      readOnly
                    />
                  </div>

                  {vendor.isCompany && (
                    <>
                      {/* Company Name Input */}
                      <div className="col-span-12">
                        <input
                          type="text"
                          className="form-input border border-mediumgray"
                          value={vendor.company}
                          readOnly
                        />
                      </div>

                      {/* ABN Input */}
                      <div className="col-span-12">
                        <input
                          type="text"
                          className="form-input border border-mediumgray"
                          value={vendor.abn}
                          readOnly
                        />
                      </div>

                      {/* GST Checkbox */}
                      <div className="col-span-12 flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={vendor.gst === "Yes"}
                          className="p-0 cursor-pointer"
                          readOnly
                        />
                        Registered for GST
                      </div>
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>

          {/* Solicitor Information */}
          <div className="col-span-12 grid grid-cols-12 gap-3">
            <label className="form-label m-0">Solicitor</label>

            <div className="col-span-12 ">
              <input
                type="text"
                className={`form-input border border-mediumgray`}
                value={solicitorName}
                placeholder="NAME"
                readOnly
              />
            </div>

            <div className="col-span-12 ">
              <input
                type="text"
                className={`form-input border border-mediumgray`}
                value={solicitorAddress}
                readOnly
              />
            </div>

            <div className="col-span-12">
              <input
                type="email"
                className={`form-input border border-mediumgray`}
                value={solicitorEmail}
                readOnly
              />
            </div>

            <div className="col-span-12 ">
              <input
                type="text"
                className={`form-input border border-mediumgray`}
                value={solicitorMobile}
                readOnly
              />
            </div>
          </div>

          <div className="col-span-12 grid grid-cols-12 gap-3">
            <div className="col-span-6 text-start">
              <label className="form-label">Status</label>
              <input
                type="text"
                className={`form-input border border-mediumgray`}
                value={status}
                readOnly
              />
            </div>
            <div className="col-span-6 text-start">
              <label className="form-label">Terms</label>

              <div className="flex items-center gap-2">
                <input
                  type="text"
                  className={`form-input border border-mediumgray`}
                  value={terms}
                  readOnly
                />
                <span>days</span>
              </div>

              <div className="flex items-center gap-2 relative">
                <p className="italic">*1 day termination</p>{" "}
                <Tooltip
                  className="w-[150px]"
                  text={<i className="fa fa-info-circle text-xs"></i>}
                  tooltip="Termination clause: The vendor may terminate this agreement at any time with one (1) days notice."
                />
              </div>
            </div>
          </div>

          <div className="col-span-12 grid grid-cols-12 gap-3">
            <div className="col-span-6 text-start">
              <div className="flex items-start gap-2">
                <label className="form-label">Price & Fee</label>
                <Tooltip
                  className="w-[200px]"
                  text={<i className="fa fa-info-circle text-xs"></i>}
                  tooltip="This price is the agent’s estimated selling price, determined through a logical assessment of recent nearby sales. The vendor has no obligation to sell the property."
                />
              </div>

              <div className="flex">
                <input
                  type="text"
                  className={`form-input rounded-none border border-mediumgray`}
                  value={startPrice}
                  readOnly
                />

                <input
                  type="text"
                  className={`form-input rounded-none border border-mediumgray`}
                  value={endPrice}
                  readOnly
                />
              </div>

              <div className="flex items-center gap-2 justify-start relative">
                <input
                  type="text"
                  className={`form-input rounded-none border border-t-0 border-b-0 border-mediumgray`}
                  value={commissionFee}
                  readOnly
                />
                <span className="absolute right-2">%</span>
              </div>

              <input
                value={commissionRange}
                className="form-input rounded-none border border-mediumgray"
                readOnly
              />
            </div>

            <div className="col-span-6 text-start">
              <label className="form-label">Marketing</label>
              <div className="border border-mediumgray py-1 px-2">
                <p>As per shopping cart</p>
                <p
                  className="underline cursor-pointer"
                  onClick={() => setMarketingTable(true)}
                >
                  View
                </p>
              </div>
            </div>
          </div>

          <div className="col-span-12 grid grid-cols-12 gap-3">
            <div className="col-span-6 flex flex-col justify-between">
              <label className="form-label">Prepare Marketing</label>
              <input
                type="text"
                className={`form-input border border-mediumgray`}
                value={prepareMarketing}
                readOnly
              />
            </div>
            <div className="col-span-6 flex flex-col justify-between">
              <label className="form-label">Access</label>
              <input
                type="text"
                className={`form-input border border-mediumgray`}
                value={access}
                readOnly
              />
            </div>
          </div>

          <div>
            <label className="form-label">Services</label>
            <div className="col-span-12 grid grid-cols-12 gap-3">
              <div className="col-span-4 flex flex-col justify-between">
                <label
                  className="form-label m-0 font-normal"
                  style={{ fontSize: "10px" }}
                >
                  Building & Pest
                </label>
                <div className="flex flex-col mt-auto">
                  <p className="italic" style={{ fontSize: "10px" }}>
                    *Upfront $55
                  </p>
                  <input
                    type="text"
                    className={`form-input border border-mediumgray`}
                    value={services[0].value}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-span-4 flex flex-col justify-between">
                <label
                  className="form-label m-0 font-normal"
                  style={{ fontSize: "10px" }}
                >
                  Strata Report
                </label>
                <div className="flex flex-col mt-auto">
                  <p className="italic" style={{ fontSize: "10px" }}>
                    *Upfront $50
                  </p>
                  <input
                    type="text"
                    className={`form-input border border-mediumgray`}
                    value={services[1].value}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-span-4 flex flex-col justify-between">
                <label
                  className="form-label m-0 font-normal"
                  style={{ fontSize: "10px" }}
                >
                  Styling
                </label>
                <div className="flex flex-col mt-auto">
                  <input
                    type="text"
                    className={`form-input border border-mediumgray`}
                    value={services[2].value}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-12 text-start">
            <label className="form-label">ID Check</label>
            <p className="italic">
              *Sight ID, credit card, water rates, council rates
            </p>
            <input
              type="text"
              className={`form-input border border-mediumgray`}
              value={fraudPrevention}
              readOnly
            />
          </div>

          {/* Signature Pad */}
          <div className="w-full flex flex-col sm:flex-row sm:gap-4">
            {vendors.map((vendor, index) => (
              <div key={index} className="mb-4 w-full sm:w-1/2">
                <div className="w-full text-start relative">
                  {!isSigned[index] && !vendor.isSigned ? (
                    <>
                      <p>
                        {vendor.firstName} {vendor.lastName}, please sign below
                      </p>
                      <div className="border border-mediumgray overflow-hidden">
                        <SignatureCanvas
                          ref={(el) => (sigCanvasRefs.current[index] = el)}
                          penColor="black"
                          canvasProps={{
                            className:
                              "w-full max-w-[400px] h-[120px] signature-canvas bg-white",
                          }}
                          clearOnResize={false}
                        />
                      </div>
                      <div className="absolute top-28 right-2 flex space-x-2">
                        <Button
                          onClick={() => clear(index)}
                          className="text-xs"
                        >
                          Clear
                        </Button>
                      </div>

                      <div className="flex justify-between mt-2">
                        <Button
                          onClick={() => handleSendToSign(index)}
                          className="gray-button"
                          disabled={sendToSignLoading && loadingIndex === index}
                          loading={sendToSignLoading && loadingIndex === index}
                        >
                          {vendor.sentDate ? "Resend" : "Send to Sign"}
                        </Button>

                        <div className="flex items-center gap-2">
                          <p onClick={generateAndOpenPDF} className="underline">
                            View Agreement
                          </p>
                          <Button
                            className="black-button"
                            onClick={() => handleSaveClick(index)}
                            loading={loading}
                          >
                            Finish
                          </Button>
                        </div>
                      </div>

                      {/* Modal for Sign Confirmation */}
                      <Modal
                        isOpen={signConfirm[index]}
                        onClose={() =>
                          setSignConfirm({ ...signConfirm, [index]: false })
                        }
                        title="Sign Confirmation"
                      >
                        <div className="flex flex-col items-center justify-center gap-4">
                          <p>
                            I, {vendor.firstName} {vendor.lastName}, confirm I
                            am {vendor.firstName} {vendor.lastName} as
                            identified by documents provided and/or sighted by
                            Ausrealty. This signature is my own and I also
                            confirm I agree to this electronic signing format
                            and the agency agreement terms and conditions.
                          </p>

                          <div className="flex justify-between items-center w-full">
                            <div className="flex items-center gap-2">
                              <input
                                type="checkbox"
                                className="p-0"
                                checked={agreeTerms[index] || false}
                                onChange={(e) =>
                                  setAgreeTerms({
                                    ...agreeTerms,
                                    [index]: e.target.checked,
                                  })
                                }
                              />
                              <label
                                onClick={() => {
                                  setSignConfirm({
                                    ...signConfirm,
                                    [index]: false,
                                  });
                                  setTermsOpen({
                                    ...termsOpen,
                                    [index]: true,
                                  });
                                }}
                                className="underline cursor-pointer"
                              >
                                TERMS
                              </label>
                            </div>
                          </div>
                          {errors[index]?.agreeTerms && (
                            <span className="form-error-message pt-1 text-red-500">
                              You must agree to the terms before submitting your
                              signature
                            </span>
                          )}

                          <div className="w-full flex gap-2 justify-end py-4">
                            <Button
                              onClick={() =>
                                setSignConfirm({
                                  ...signConfirm,
                                  [index]: false,
                                })
                              }
                              className="gray-button"
                            >
                              No
                            </Button>
                            <Button
                              onClick={() => handleConfirmSign(index)}
                              className="black-button"
                              loading={loading}
                            >
                              Yes
                            </Button>
                          </div>
                        </div>
                      </Modal>

                      {/* Modal for Agreed Terms */}
                      <Modal
                        isOpen={termsOpen[index]}
                        onClose={() => {
                          setTermsOpen({ ...termsOpen, [index]: false });
                          setSignConfirm({ ...signConfirm, [index]: true });
                        }}
                        title=""
                        className="max-w-4xl max-h-[94vh] px-2"
                      >
                        <AuthoriseScheduleEditorLock formData={authData} />
                      </Modal>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center gap-2">
                        <p>
                          {vendor.firstName} {vendor.lastName} Signed
                          Successfully
                        </p>
                      </div>
                      {(signatureDataURLs[index] ||
                        vendor.signatureUrl ||
                        vendor.signature) && (
                        <div className="mt-2">
                          <img
                            src={
                              signatureDataURLs[index] ||
                              vendor.signatureUrl ||
                              vendor.signature
                            }
                            alt="Signature"
                            className="w-auto p-2 h-16 object-fill border border-mediumgray"
                          />
                          <p
                            onClick={generateAndOpenPDF}
                            className="underline cursor-pointer"
                          >
                            View Agreement
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <Modal
        isOpen={marketingTable}
        onClose={() => setMarketingTable(false)}
        title="Shopping Cart"
      >
        {/* <MarketingPrice /> */}
        <MarketingPrice
          isEdit={false}
          existingData={marketing} // Watch for existing form data
        />
      </Modal>
        {/* Modal for Marketing Table */}
        {/* <Modal
          isOpen={marketingTable}
          onClose={() => setMarketingTable(false)}
          title="Marketing"
        >
          <div className="overflow-x-auto">
            <table className="w-full border">
              <tbody>
                {marketing.marketingItems.map((item, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">{item.name}</td>
                    <td className="border px-4 py-2"></td>
                  </tr>
                ))}

                <tr>
                  <td className="border px-4 py-2 font-bold">TOTAL</td>
                  <td className=" px-4 py-2 flex items-center">
                    {marketing.marketingPrice}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal> */}
      </div>

      <Modal
        isOpen={resetConfirm}
        onClose={() => setResetConfirm(false)}
        title="Reset Agreement"
      >
        <div className="flex flex-col items-center justify-center">
          <p>
            Are you sure you want to reset the agreement? Once reset it cannot
            be reverted
          </p>
          <div className="w-full flex gap-2 justify-end py-4">
            <Button
              onClick={() => setResetConfirm(false)}
              className="gray-button"
            >
              No
            </Button>
            <Button onClick={resetAuthSchedule} className="black-button">
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AuthoriseScheduleLock;
