import { useState, useEffect } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { HiChevronDown } from "react-icons/hi2";
import { formatCurrency } from "utils/helperFunctions";
import axios from "utils/axios";
import Button from "./Button";
import Calendar from "react-calendar";
import Modal from "./Modal";

const MarketingPrice = ({
  price,
  suburb,
  tab = "authorise-and-schedule",
  onConfirm,
  existingData,
  isEdit = "true",
}) => {
  const [categories, setCategories] = useState([]);
  const [agentContribution, setAgentContribution] = useState(
    existingData?.agentContribution?.amount || "$0"
  );

  const [viewCart, setViewCart] = useState(false);
  const [isAgentContributionChecked, setIsAgentContributionChecked] = useState(
    existingData?.agentContribution?.isChecked || false
  );
  const [deferOption, setDeferOption] = useState(null); // Track Yes/No option for Defer
  const [deferNote, setDeferNote] = useState(""); // Track the note
  const [deferDate, setDeferDate] = useState(""); // Track the date

  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     try {
  //       const response = await axios.get(`/marketingPrice/${price}/${suburb}`);
  //       if (response.data.success) {
  //         const initializedCategories = response.data.data.categories.map((category) => ({
  //           ...category,
  //           items: category.items.map((item) => ({
  //             ...item,
  //             isChecked: false,
  //           })),
  //         }));
  //         setCategories(initializedCategories);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching categories:", error);
  //     }
  //   };

  //   if (existingData?.categories) {
  //     setCategories(existingData?.categories); // Initialize categories with existing data
  //   } else {
  //     fetchCategories(); // Fetch categories if no existing data
  //   }

  //   if (existingData?.total) {
  //     setViewCart(true);
  //   }
  // }, [existingData]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`/marketingPrice/${price}/${suburb}`);
        if (response.data.success) {
          const initializedCategories = response.data.data.categories.map((category) => {
            // Check if the category is "Internet Portals"
            if (category.category === "Internet Portals") {
              return {
                ...category,
                items: category.items.map((item, index) => ({
                  ...item,
                  // Set the first two items to isChecked: true by default
                  isChecked: index < 2 ? true : false,
                })),
              };
            } else {
              return {
                ...category,
                items: category.items.map((item) => ({
                  ...item,
                  isChecked: false,
                })),
              };
            }
          });
          setCategories(initializedCategories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
  
    if (existingData?.categories) {
      setCategories(existingData?.categories); // Initialize categories with existing data
    } else {
      fetchCategories(); // Fetch categories if no existing data
    }
  
    if (existingData?.total) {
      setViewCart(true);
    }
  }, [existingData]);

  // Handle checkbox change
  const handleCheckboxChange = (id) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => ({
        ...category,
        items: category.items.map((item) =>
          item._id === id ? { ...item, isChecked: !item.isChecked } : item
        ),
      }))
    );
  };


  const handlePriceChange = (id, e) => {
    const value = e.target.value;
    const numericValue = value.split("$")[1]?.replace(/,/g, "") || "0";
    const parsedValue = Math.max(0, parseFloat(numericValue) || 0);

    setCategories((prevCategories) =>
      prevCategories.map((category) => ({
        ...category,
        items: category.items.map((item) =>
          item._id === id
            ? { ...item, price: formatCurrency(parsedValue) }
            : item
        ),
      }))
    );
  };
  const handleAgentContributionChange = (e) => {
    const value = e.target.value;
    // Use split to remove the dollar sign and extract numeric part
    const numericValue = value.split("$")[1]?.replace(/,/g, "") || "0"; // Get numeric part after the dollar sign
    const parsedValue = Math.max(0, parseFloat(numericValue) || 0); // Parse the number and ensure it's non-negative
    setAgentContribution(formatCurrency(parsedValue));
  };

  const handleAgentContributionCheckboxChange = () => {
    setIsAgentContributionChecked(!isAgentContributionChecked);
  };

  // Calculate total price for selected items
  const totalPrice = categories.reduce(
    (total, category) =>
      total +
      category.items.reduce((catTotal, item) => {
        // Safely handle item.price
        const priceString =
          typeof item.price === "string"
            ? item.price
            : item.price?.toString() || "0"; // Convert to string if it's a number
        const numericPrice =
          parseFloat(priceString.replace(/^\$/, "").replace(/,/g, "")) || 0; // Ensure price is numeric
        return item.isChecked ? catTotal + numericPrice : catTotal; // Add to total if checked
      }, 0),
    0
  );
  // Apply agent contribution if applicable
  const finalTotal = Math.max(
    0,
    isAgentContributionChecked
      ? totalPrice -
          parseFloat(agentContribution.replace(/^\$/, "").replace(/,/g, ""))
      : totalPrice // Extract numeric value from formatted agent contribution
  );
  const handleConfirmClick = () => {
    onConfirm({
      categories,
      agentContribution: {
        amount: agentContribution,
        isChecked: isAgentContributionChecked,
      },
      total: finalTotal,
    });
    setViewCart(true); // Set view to cart
  };
  //defer//
  const handleDeferClick = (option) => {
    setDeferOption(option);
    if (option === "no") {
      // Close modal if "No" is selected
      setViewCart(false);
    }
  };

  // Handle note and date change
  const handleNoteChange = (e) => {
    setDeferNote(e.target.value);
  };

  const handleDateChange = (e) => {
    setDeferDate(e.target.value);
  };
  const handleCloseDefer = () => {
    setDeferOption("no");
    setDeferNote("");
    setDeferDate(null);
  };
  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize to midnight
      date.setHours(0, 0, 0, 0); // Normalize to midnight
      return date < today; // Disable dates before today
    }
    return false; // Do not disable other views
  };

  return (
    <div className="w-full mx-auto">
      {!viewCart ? (
        <>
          <table className="w-full flex justify-end border-lightgray text-xs">
            <tbody>
              {categories.map((category, categoryIndex) => (
                <>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <DisclosureButton className="w-full cursor-pointer bg-lightgray px-3 py-2 my-1 flex items-center justify-between">
                          <p className="">{category.category}</p>
                          <HiChevronDown
                            className={`transition-transform duration-300 ${
                              open ? "rotate-180" : ""
                            }`}
                          />
                        </DisclosureButton>

                        <DisclosurePanel className="origin-top transition duration-200 ease-out">
                          {category.items.map((item,index) => (
                            <tr
                              key={item._id}
                              className="hover:bg-lightgray border-b border-mediumgray flex items-center justify-between cursor-pointer"
                              onClick={() => handleCheckboxChange(item._id)} // Toggle on row click
                            >
                              <td className="py-2 px-3 text-xs">{item.name}</td>
                              <div className="flex items-center">
                                <td className="py-2 px-3 text-xs">
                                  {category.category === "Internet Portals" && index < 2 ? (
                                    <input
                                      type="text"
                                      value={item.price}
                                      placeholder="$0"
                                      onChange={(e) =>
                                        handlePriceChange(item._id, e)
                                      }
                                      className="form-input border border-mediumgray text-right"
                                    />
                                  ) : (
                                    formatCurrency(item.price)
                                  )}
                                </td>
                                <td className="py-2 px-3">
                                  <input
                                    type="checkbox"
                                    checked={item.isChecked}
                                    onChange={() =>
                                      handleCheckboxChange(item._id)
                                    } // Toggle when clicked
                                    onClick={(e) => e.stopPropagation()} // Prevent row click event
                                  />
                                </td>
                              </div>
                            </tr>
                          ))}
                        </DisclosurePanel>
                      </>
                    )}
                  </Disclosure>
                </>
              ))}

              <tr
                className="w-full hover:bg-lightgray border-b border-mediumgray flex items-center justify-between cursor-pointer"
                onClick={handleAgentContributionCheckboxChange} // Toggle on row click
              >
                <td className="py-2 px-3 text-xs w-[200px]">
                  Agent Contribution
                </td>
                <div className="flex items-center">
                  <td className="py-2 px-3 text-xs">
                    <input
                      type="text"
                      className="form-input border border-mediumgray text-right"
                      placeholder="$0"
                      value={agentContribution}
                      onChange={handleAgentContributionChange}
                    />
                  </td>
                  <td className="py-2 px-3">
                    <input
                      type="checkbox"
                      checked={isAgentContributionChecked}
                      onChange={handleAgentContributionCheckboxChange} // Toggle when clicked
                      onClick={(e) => e.stopPropagation()} // Prevent row click event
                    />
                  </td>
                </div>
              </tr>

              <tr className="hover:bg-lightgray font-semibold flex items-center justify-between">
                <td className="py-2 px-3 text-center text-xs">TOTAL</td>
                <td className="py-2 px-3 text-center text-xs">
                  {formatCurrency(finalTotal)}
                </td>
              </tr>
            </tbody>
          </table>

          {isEdit && (
            <div className="flex justify-end mt-4">
              <Button onClick={handleConfirmClick} className="black-button">
                Confirm
              </Button>
            </div>
          )}
        </>
      ) : (
        <>
          {/* Shopping Cart Modal */}
          <div className="overflow-x-auto">
            <table className="w-full border text-xs">
              <tbody>
                {categories.flatMap((category) =>
                  category.items
                    .filter((item) => item.isChecked)
                    .map((item, index) => {
                      // Safely handle item.price
                      const priceString =
                        typeof item.price === "string"
                          ? item.price
                          : item.price?.toString() || "0"; // Convert to string if it's a number
                      const numericPrice =
                        parseFloat(
                          priceString.replace(/^\$/, "").replace(/,/g, "")
                        ) || 0; // Ensure price is numeric

                      return (
                        <tr key={item._id}>
                          <td className="border px-4 py-2 text-xs">
                            {item.name}
                          </td>
                          <td className="border px-4 py-2 text-xs">
                            {formatCurrency(numericPrice)}{" "}
                            {/* Format numeric price */}
                          </td>
                        </tr>
                      );
                    })
                )}
                <tr>
                  <td className="border px-4 py-2 text-xs">
                    Agent Contribution
                  </td>
                  <td className="border-b px-4 py-2 text-xs">
                    - {agentContribution}
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 font-bold text-xs">TOTAL</td>
                  <td className="px-4 py-2 text-xs">
                    {formatCurrency(finalTotal)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {isEdit && (
            <div className="mt-4 flex flex-col justify-between space-y-4">
              <div className="w-full flex justify-between">
                <Button
                  onClick={() => setViewCart(false)}
                  className="gray-button"
                >
                  Continue Shopping
                </Button>
                <Button
                  onClick={() => handleDeferClick("yes")}
                  className="black-button"
                >
                  Defer
                </Button>
              </div>

              {tab === "authorise-and-schedule" && (
                <>
                  <div className="flex items-start flex-col">
                    <Button className="gray-button">Pay Later</Button>
                    <p className="text-xs italic">*incurs 6.9% fee</p>
                    <div className="mt-2 text-center text-darkergray text-xs">
                      OR SAVE 6% AND PAY NOW
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {/* Defer Options - Yes/No */}
          {deferOption === "yes" && (
            <div className="mt-4 relative">
              {/* Close button at the top-right corner */}
              <i
                onClick={handleCloseDefer}
                class="absolute top-0 right-2 fa-solid fa-xmark"
              ></i>
              <label>Write a note:</label>
              <textarea
                className="form-input border w-full mt-2"
                value={deferNote}
                onChange={handleNoteChange}
              />
              <label>Select a date:</label>
              <div className="flex justify-start mt-3">
                <Calendar
                  tileDisabled={tileDisabled}
                  prev2Label={null}
                  next2Label={null}
                  onChange={setDeferDate}
                  value={deferDate}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default MarketingPrice;
